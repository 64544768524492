import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { ObraCard } from "../Repertorio/ObraCard";

import styles from "./Publicacion.module.scss";
import { buscarPorCriterio } from "../../helpers/buscarPorCriterio";
import { dataSource, paths } from "../../data/dataSourese";
import { useNavigate } from "react-router-dom";
import { MyModal } from "../MyModal/MyModal";

export const Publicacion = ({
  tituloPublicacion,
  imagenPublicacion,
  linkPublicacion,
  infoPublicacion,
  obrasIncluidas,
  ID,
}) => {
  const navigate = useNavigate();

  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}/${obraElegida}`);
  };
  const handleClickBuy = (linkPublicacion) => {
    window.open(`${linkPublicacion}`, "_blnak");
  };

  // MyModal settings
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (data) => {
    setUrl(data);
    setShow(true);
  };

  return (
    <Container fluid className={`mt-5 mb-5`}>
      <h2 className={`${styles.tituloPublicacion}`}>{tituloPublicacion}</h2>
      <Row>
        <Col md={4}>
          <Col onClick={() => handleShow(imagenPublicacion)}>
            <Image
              src={imagenPublicacion}
              fluid
              className={`${styles.imagenPublicacion}`}
            />
          </Col>
          <Row className={`mt-3 ${styles.buyButton}`}>
            <Button
              variant="secondary"
              onClick={() => handleClickBuy(linkPublicacion)}
            >
              <strong>Comprar</strong>
            </Button>
          </Row>
        </Col>
        <Col>
          <h4>Reseña</h4>
          <p>{infoPublicacion}</p>
          <Row>
            {obrasIncluidas.length > 2 ? (
              <h4>Obras relacionadas:</h4>
            ) : (
              <h4>Obra relacionada:</h4>
            )}
            <Row xs={2} md={4} lg={4} className="g-3 mb-3 mt-1">
              {Array.from(obrasIncluidas).map((obra, id) => (
                <Col key={id} id={obra}>
                  <ObraCard
                    obra={buscarPorCriterio({
                      array: dataSource,
                      propiedad: "ID",
                      criterio: obra,
                    })}
                    handleClick={handleClick}
                    subTitle={false}
                    footer={false}
                  />
                </Col>
              ))}
            </Row>
          </Row>
        </Col>
      </Row>
      <hr className="mt-4" />
      <MyModal show={show} handleClose={handleClose} url={url} size="md" />
    </Container>
  );
};
