import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Col, Container, Row } from "react-bootstrap";
import { ciclos, paths } from "../../data/dataSourese";
import { Login } from "../Login/Login";
import { DataContex } from "../../context/DataContext";
import { MaterialABMForm } from "../MaterialABMForm/MaterialABMForm";

export const MaterialCreate = () => {
  const { userIsLogin } = useContext(DataContex);
  const [description, setDescription] = useState("");
  const [descargas, setDescargas] = useState(0);
  const [niveles, setNiveles] = useState([]);
  const [obraID, setObraID] = useState("");
  const [tipo, setTipo] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const recursosCollection = collection(db, "recursos");
  const listaDeRecursos = async (e) => {
    e.preventDefault();

    try {
      await addDoc(recursosCollection, {
        description,
        descargas,
        niveles: niveles,
        obraID,
        tipo,
        link,
        title,
      });
      navigate(`/${paths.abm}`);
    } catch (error) {
      console.error("Error al agregar documento: ", error);
      navigate(`/${paths.abm}`);
    }
  };

  const handleCheckboxChange = (e) => {
    const nivelSeleccionado = e.target.id; // Obtiene el id del checkbox seleccionado

    if (e.target.checked) {
      setNiveles((prevNiveles) => [...prevNiveles, nivelSeleccionado]); // Agrega el nivel seleccionado al array
    } else {
      setNiveles((prevNiveles) =>
        prevNiveles.filter((nivel) => nivel !== nivelSeleccionado)
      ); // Elimina el nivel seleccionado del array
    }
  };

  return (
    <Row>
      <Container fluid>
        <Container>
          <h1>MaterialCreate</h1>
          {userIsLogin ? (
            <>
              <MaterialABMForm
                onSubmit={listaDeRecursos}
                submitText={"Crear nuevo material"}
                description={description}
                setDescription={setDescription}
                descargas={descargas}
                setDescargas={setDescargas}
                ciclos={ciclos}
                niveles={niveles}
                handleCheckboxChange={handleCheckboxChange}
                obraID={obraID}
                setObraID={setObraID}
                tipo={tipo}
                setTipo={setTipo}
                setLink={setLink}
                link={link}
                title={title}
                setTitle={setTitle}
              />
            </>
          ) : (
            <Col className="mb-5 mt-5">
              <Login />
            </Col>
          )}
        </Container>
      </Container>
    </Row>
  );
};

export {};
