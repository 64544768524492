import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Dropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import { DataContex } from "../../context/DataContext";

import styles from "./Recursos.module.scss";
// import shareStyles from '../../scss/sharedStyles.module.scss'
import { MaterialDownloader } from "../MaterialDownloader/MaterialDownloader";

export const Recursos = () => {
  const { obrasRecursos, recursosMapeadosFire } = useContext(DataContex);

  const [recursosSorted, setRecursosSorted] = useState([]);

  const [tipoDeOrden, setTipoDeOrden] = useState("asc");

  const [listaObras, setListaObras] = useState([]);

  const [obraFiltrada, setObraFiltrada] = useState("Todas");

  const [tipoDeMaterial, setTipoDeMaterial] = useState("Todos");

  const [cicloFiltrado, setCicloFiltrado] = useState({
    inicial: true,
    primerCiclo: true,
    segundoCiclo: true,
    tercerCiclo: true,
    secundario: true,
    text: "Todos",
  });

  const ordenarMaterialPorCriterio = (material, criterio) =>
    material.sort((a, b) => {
      const keyA = a.title + a.metrialID;
      const keyB = b.title + b.metrialID;

      if (criterio === "asc") {
        return keyA.localeCompare(keyB);
      } else if (criterio === "desc") {
        return keyB.localeCompare(keyA);
      } else {
        // Si el criterio no es válido, no se realiza la ordenación
        return 0;
      }
    });

  useEffect(() => {
    setRecursosSorted(
      ordenarMaterialPorCriterio(recursosMapeadosFire, tipoDeOrden)
    ); //obrasRecursos recursosMapeadosFire
    const obrasSet = new Set();
    recursosSorted.forEach((obra) => {
      obrasSet.add(obra.title);
    });
    const listaObras = Array.from(obrasSet);
    setListaObras(listaObras);
  }, [obrasRecursos, recursosMapeadosFire, recursosSorted, tipoDeOrden]);

  const Recurso = ({
    title,
    metrialID,
    link,
    description,
    inicial,
    primerCiclo,
    segundoCiclo,
    tercerCiclo,
    secundario,
    tipo,
    descargas,
    id,
  }) => {
    const data = {
      title,
      metrialID,
      link,
      description,
      inicial,
      primerCiclo,
      segundoCiclo,
      tercerCiclo,
      secundario,
      tipo,
      descargas,
      id,
    };

    return (
      <Col style={{ display: "block" }}>
        <strong>
          <h4>{title}</h4>
        </strong>
        <br />
        <MaterialDownloader data={data} />
      </Col>
    );
  };

  return (
    <Container className={styles.recursos}>
      <Row className={`mb-5 align-items-end ${styles.recurosHeader}`}>
        <Col>
          <h2>{recursosSorted.length} materiales didácticos</h2>
          <h4>
            {" "}
            En este apartado hay investigaciones, actividades y crucigramas para
            descargar.
          </h4>
          <p>
            {" "}
            <b>
              Están agrupados según las siguientes categorías en orden
              alfabético:
            </b>
            <br />• Cristóbal Colón • Domingo F. Sarmiento • Historia de San
            Isidro • Independencia • Invasiones inglesas • José de San Martín •
            Manuel Belgrano • Pueblos originarios • Revolución de Mayo •
            Soberanía • Tradición • Vestimenta • Vida cotidiana,
            costumbres y comidas
          </p>
        </Col>
      </Row>
      <Navbar sticky='top' className={`mb-5 ${styles.filterBar}`}>
        <Container className={`${styles.filterBarContainer}`} fluid>
          <Accordion defaultActiveKey='0' style={{ width: "100%" }}>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Filtrar por:</Accordion.Header>
              <Accordion.Body>
                <Row style={{ width: "100%" }} className='pb-2'>
                  <Col md={4} className={`${styles.filterContainer}`}>
                    <Col>Categoría</Col>
                    <Col className={`${styles.dropDownContainer}`}>
                      <Dropdown className={`${styles.dropButton}`}>
                        <Dropdown.Toggle id='dropdown-basic'>
                          {obraFiltrada}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{ overflowY: "auto", height: "75vh" }}>
                          <Dropdown.Item
                            onClick={() => setObraFiltrada("Todas")}>
                            Todas
                          </Dropdown.Item>
                          {Array.from(listaObras).map((data, id) => (
                            <Dropdown.Item
                              key={id}
                              onClick={() => setObraFiltrada(data)}>
                              {data}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Col>
                  <Col md={2} className={`${styles.filterContainer}`}>
                    <Col>Orden</Col>
                    <Col className={`${styles.dropDownContainer}`}>
                      <Dropdown className={`${styles.dropButton}`}>
                        <Dropdown.Toggle id='dropdown-basic'>
                          {tipoDeOrden === "asc" ? "Ascendente" : "Descendete"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setTipoDeOrden("desc")}>
                            Descendete
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setTipoDeOrden("asc")}>
                            Ascendente
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Col>

                  <Col xs={12} md={3} className={`${styles.filterContainer}`}>
                    <Col>Tipo de Material</Col>
                    <Col className={`${styles.dropDownContainer}`}>
                      <Dropdown className={`${styles.dropButton}`}>
                        <Dropdown.Toggle id='dropdown-basic'>
                          {tipoDeMaterial}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setTipoDeMaterial("Todos")}>
                            Todos
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setTipoDeMaterial("Actividad")}>
                            Actividades
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setTipoDeMaterial("Investigación")}>
                            Investigaciones
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setTipoDeMaterial("Crucigrama")}>
                            Crucigramas
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Col>
                  <Col xs={12} md={2} className={`${styles.filterContainer}`}>
                    <Col>Ciclo</Col>
                    <Col className={`${styles.dropDownContainer}`}>
                      <Dropdown className={`${styles.dropButton}`}>
                        <Dropdown.Toggle id='dropdown-basic'>
                          {cicloFiltrado.text}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: false,
                                primerCiclo: false,
                                segundoCiclo: false,
                                tercerCiclo: false,
                                secundario: false,
                                text: "Todos",
                              })
                            }>
                            Todos
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: true,
                                primerCiclo: false,
                                segundoCiclo: false,
                                tercerCiclo: false,
                                secundario: false,
                                text: "Inicial",
                              })
                            }>
                            Inicial
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: false,
                                primerCiclo: true,
                                segundoCiclo: false,
                                tercerCiclo: false,
                                secundario: false,
                                text: "Primer ciclo",
                              })
                            }>
                            Primer ciclo
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: false,
                                primerCiclo: false,
                                segundoCiclo: true,
                                tercerCiclo: false,
                                secundario: false,
                                text: "Segundo ciclo",
                              })
                            }>
                            Segundo ciclo
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: false,
                                primerCiclo: false,
                                segundoCiclo: false,
                                tercerCiclo: true,
                                secundario: false,
                                text: "Tercer ciclo",
                              })
                            }>
                            Tercer ciclo
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setCicloFiltrado({
                                inicial: false,
                                primerCiclo: false,
                                segundoCiclo: false,
                                tercerCiclo: false,
                                secundario: true,
                                text: "Secundario",
                              })
                            }>
                            Secundario
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </Navbar>
      <ul>
        {Array.from(recursosSorted).map((data, id) => (
          <div key={id}>
            {data.tipo === tipoDeMaterial || tipoDeMaterial === "Todos" ? (
              <>
                {data.title === obraFiltrada || obraFiltrada === "Todas" ? (
                  <>
                    {data.inicial && cicloFiltrado.text === "Inicial" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}
                    {data.primerCiclo &&
                    cicloFiltrado.text === "Primer ciclo" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}
                    {data.segundoCiclo &&
                    cicloFiltrado.text === "Segundo ciclo" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}
                    {data.tercerCiclo &&
                    cicloFiltrado.text === "Tercer ciclo" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}
                    {data.secundario && cicloFiltrado.text === "Secundario" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}

                    {(data.inicial ||
                      data.primerCiclo ||
                      data.segundoCiclo ||
                      data.tercerCiclo ||
                      data.secundario) &&
                    cicloFiltrado.text === "Todos" ? (
                      <Recurso
                        title={data.title}
                        metrialID={data.metrialID}
                        link={data.link}
                        description={data.description}
                        inicial={data.inicial}
                        primerCiclo={data.primerCiclo}
                        segundoCiclo={data.segundoCiclo}
                        tercerCiclo={data.tercerCiclo}
                        secundario={data.secundario}
                        tipo={data.tipo}
                        descargas={data.descargas}
                        id={data.id}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        ))}
      </ul>
    </Container>
  );
};
