import { useLottie } from "lottie-react";
import { useEffect, useState } from "react";

export const LottieConatiner = ({
  lottieJason,
  lottieBg = "transparent",
  viewerHeigth = "300px",
  loop = true,
  autoplay = true,
  animSpeed = 60,
  classSendet = "lottie",
  
}) => {

  const [classToLottie, setClassToLottie ] = useState(classSendet);

  useEffect(() => {
    setClassToLottie(classSendet);

  }, [classSendet]);
  

  const style = {
    height: viewerHeigth,
    backgroundColor: lottieBg,
   
  };

  const options = {
    animationData: lottieJason,
    loop,
    autoplay,
    className: classToLottie,
  };

  const { View } = useLottie(options, style);

  options.animationData.fr = animSpeed;

  return View;
};
