import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig/firebase";
// Asegúrate de importar las funciones necesarias de Firebase

export const useIncrementFilesDown = () => {
  const incrementFilesDown = async (id) => {
    const docRef = doc(db, "recursos", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const filesDown = parseInt(data.descargas, 10) || 0; // Parsea a entero o establece 0 si no es un número válido
      const updatedFilesDown = filesDown + 1; // Incrementa en 1
      await updateDoc(docRef, { descargas: updatedFilesDown.toString() });
    }
  };

  return { incrementFilesDown };
};
