import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { HeroSlider } from "../HeroSlider/HeroSlider";
import { StatCard } from "../StatCard/StatCard";
import { SponsorSlider } from "../SponsorSlider/SponsorSlider";
import { paths, sponsors } from "../../data/dataSourese";
import {
  funcionesImg,
  generoPropio,
  personaje01,
  personaje02,
  personaje03,
  personaje04,
  personaje05,
  personaje06,
} from "../../assets";
import { TituloSeccion } from "../TituloSeccion/TituloSeccion";
import { ImageAnimated } from "../ImageAnimated/ImageAnimated";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import { DataContex } from "../../context/DataContext";
import styles from "./Pages.module.scss";
import stylesHome from "./HomePage.module.scss";
import sharedStyles from "../../scss/sharedStyles.module.scss";
import useBreakpointValue from "../../hooks/useBreakpointValue";
import Helmet from "../Helmet";

export const HomePage = () => {
  const { windowSize } = useContext(DataContex);
  const navigate = useNavigate();
  const [bannerHeight, setBannerHeight] = useState("45vh");
  const handleClickCTA = (path, idDestino) => {
    const fullPath = idDestino ? `${path}#${idDestino}` : path;
    navigate(fullPath);
  };

  useEffect(() => {
    switch (windowSize.windowsName) {
      case "appXs":
        setBannerHeight("50vh");
        break;
      case "appSm":
        setBannerHeight("40vh");
        break;
      case "appMd":
        setBannerHeight("35vh");
        break;
      case "appLg":
        setBannerHeight("20vh");
        break;
      case "appXxl":
        setBannerHeight("38vh");
        break;
      case "appFull":
        setBannerHeight("38vh");
        break;
      case "app4k":
        setBannerHeight("30vh");
        break;
      // Otros casos para cada breakpoint
      default:
        // Establece un valor predeterminado si el nombre del breakpoint no coincide con ninguno
        setBannerHeight("45vh");
    }
  }, [windowSize]);

  return (
    <>
      <Helmet
        title={"Home Page"}
        largeTwitterCard={true}
        link={`${paths.home}`}
        location={{}}
      />
      <Container fluid className={`${styles.page}`}>
        {/* Hero Text */}
        <Container fluid className={`${stylesHome.heroText01} `}>
          <Container
            className={`${stylesHome.heroTextWarper}`}
            style={{ minHeight: `${bannerHeight}` }}>
            {windowSize.pixelWidth < useBreakpointValue("appMd") ? (
              <Col className={`${stylesHome.imageContainer} h-100`}>
                <Col className={`${stylesHome.leftBox}`}>
                  <ImageAnimated
                    images={[
                      personaje04,
                      personaje05,
                      personaje02,
                      personaje06,
                      personaje01,
                      personaje03,
                    ]}
                  />
                </Col>
              </Col>
            ) : (
              <Row
                className={`${stylesHome.imageContainer} h-100 justify-content-between`}>
                <Col className={`${stylesHome.leftBox}`}>
                  <ImageAnimated
                    images={[personaje04, personaje05, personaje02]}
                  />
                </Col>
                <Col className={`${stylesHome.rigthBox}`}>
                  <ImageAnimated
                    images={[personaje06, personaje01, personaje03]}
                    timeOpu={1000}
                  />
                </Col>
              </Row>
            )}
            <Container className={`${stylesHome.text}`}>
              <Row className={`${stylesHome.textContainer} h-100`}>
                <Col md={10} className={``}>
                  <p>Bienvenidos al</p>
                  <h2>Museo Viajero</h2>
                  <p>
                    <strong>
                      Compañía de teatro itinerante e investigación histórica
                      fundada en 1995
                    </strong>
                  </p>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>

        {/* Creadores de un genero propio */}
        <Row className={`mt-4 mb-4 ${stylesHome.creadoresSection}`}>
          <Container fluid>
            <Container>
              <Row className={`${stylesHome.creadores} h-100`}>
                <Col
                  md={7}
                  className={`${stylesHome.imgCreadores} text-center`}>
                  <Image src={generoPropio} fluid />
                </Col>
                <Col>
                  <Row className={`align-items-center h-100`}>
                    <Col className={`${sharedStyles.float}`}>
                      <Col className={`${stylesHome.textCreadores}`}>
                        <h4>
                          Creadores de un género:
                          <strong>
                            <i> la comedia histórica infantil </i>
                          </strong>
                          que combina una sólida investigación histórica con
                          todos los recursos teatrales
                        </h4>
                        <Row className='mt-3'>
                          <Button
                            onClick={(path) =>
                              handleClickCTA((path = paths.nosotros), "crono")
                            }>
                            Ver trayectoria
                          </Button>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
        </Row>

        {/* Funciones en el teatro */}
        <Row className={` mb-4 ${stylesHome.funcionesSection}`}>
          <Container fluid>
            <Container>
              <Row className={`${stylesHome.funciones} `}>
                <Col
                  md={7}
                  className={`${stylesHome.imgFunciones} text-center order-sm-1`}>
                  <Image src={funcionesImg} fluid />
                </Col>
                <Col className='order-sm-0'>
                  <Row className={`align-items-center h-100`}>
                    <Col className={`${sharedStyles.float}`}>
                      <Col className={`${stylesHome.textFunciones}`}>
                        <h4>
                          Funciones en el teatro con reserva previa.
                          <br />
                          Viajamos a las escuelas.
                          <br />
                        </h4>
                        <span>
                          Hasta dos funciones por turno separando los ciclos.
                          Solo necesitamos un espacio cubierto.
                        </span>
                        <Row className='mt-3'>
                          <Button
                            onClick={(path) =>
                              handleClickCTA((path = paths.obras))
                            }>
                            Elegir obra
                          </Button>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
        </Row>
        <TituloSeccion titulo={"Novedades"} />

        {/* Slider */}

        <Container fluid className={`${styles.page}`}>
          <HeroSlider />
        </Container>

        {/* Stats */}
        <Container fluid>
          <StatCard />
        </Container>
        <SponsorSlider slides={sponsors} />
      </Container>
      <ScrollPercentage maxScroll={20} />
    </>
  );
};
