import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { gsap } from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faDownload,
  faListOl,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./MaterialDownloader.module.scss";
import { pdfDownloadIcon } from "../../assets";
import { useIncrementFilesDown } from "../../hooks/useIncrementFilesDown";
import { DataContex } from "../../context/DataContext";

export const MaterialDownloader = ({ data, numeroDeDescargas = true }) => {
  const { windowSize } = useContext(DataContex);
  const downloader = useRef(null);
  const { incrementFilesDown } = useIncrementFilesDown();
  // const handleCkickPDF = (file, id) => {
  //   console.log(file, id);
  //   window.open(file);
  //   incrementFilesDown(id);
  // };
  // const handleCkickPDF = (file, id = "sinId") => {
  //   console.log(id);
  //   window.open(file);
  //   incrementFilesDown(id);
  // };

  const handleCkickPDF = (file, id = "sinId") => {
    try {
      console.log(id);
      window.open(file);
      incrementFilesDown(id);
    } catch (error) {
      console.error("Error en handleCkickPDF:", error);
    }
  };

  const [over, setOver] = useState(false);
  const [out, setOut] = useState(true);
  const mouseOver = () => {
    setOver(true);
    setOut(false);
  };
  const mouseOut = () => {
    setOver(false);
    setOut(true);
  };

  useEffect(() => {
    if (over) {
      gsap.to(downloader.current, { y: 300, duration: 0.2, ease: "ease.out" });
    }
  }, [over]);

  useEffect(() => {
    if (out) {
      gsap.to(downloader.current, { y: 0, duration: 0.2, ease: "ease.out" });
    }
  }, [out]);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  return (
    <Col
      className={`${styles.framne}`}
      // onClick={() => handleCkickPDF(data.link, data.id)}
      onClick={() => handleCkickPDF(data.link, data.id ? data.id : "sinId")}
      role='button'>
      <Col
        ref={downloader}
        className={`${styles.dataText}`}
        onMouseOver={mouseOver}
        onMouseLeave={mouseOut}>
        <Row>
          {isXs ? (
            ""
          ) : (
            <Col xs={2}>
              <Image src={pdfDownloadIcon} fluid />
            </Col>
          )}

          <Col>
            {data.tipo === "Actividad" ? (
              <li className={`${styles.tipo}`}>
                <strong>
                  <Row className={``}>
                    <Col className={``}>
                      <FontAwesomeIcon icon={faPen} size='2xl' />
                    </Col>
                    <Col className={``}>Actividad</Col>
                  </Row>
                </strong>
              </li>
            ) : (
              ""
            )}
            {data.tipo === "Investigación" ? (
              <li className={`${styles.tipo}`}>
                <strong>
                  <Row>
                    <Col>
                      <FontAwesomeIcon icon={faBook} size='2xl' />
                    </Col>
                    <Col>Investigación</Col>
                  </Row>
                </strong>
              </li>
            ) : (
              ""
            )}
            {data.tipo === "Crucigrama" ? (
              <li className={`${styles.tipo}`}>
                <strong>
                  <Row>
                    <Col>
                      <FontAwesomeIcon icon={faListOl} size='2xl' />
                    </Col>
                    <Col>Crucigrama</Col>
                  </Row>
                </strong>
              </li>
            ) : (
              ""
            )}
            <p>
              <strong>{data.description}</strong>
            </p>
          </Col>
          <hr />
        </Row>
        <Row xs={12} className={styles.tituloCiclos}>
          <p>Este material es para:</p>
        </Row>
        <Row xs={1} className={`g-2`}>
          <ul>
            {data.inicial ? (
              <li className={` ${styles.ciclos}`}>Inicial</li>
            ) : (
              ""
            )}
            {data.primerCiclo ? (
              <li className={`  ${styles.ciclos}`}>Primer Ciclo</li>
            ) : (
              ""
            )}
            {data.segundoCiclo ? (
              <li className={`  ${styles.ciclos}`}>Segundo Ciclo</li>
            ) : (
              ""
            )}
            {data.tercerCiclo ? (
              <li className={`  ${styles.ciclos}`}>Tercer Ciclo</li>
            ) : (
              ""
            )}
            {data.secundario ? (
              <li className={`  ${styles.ciclos}`}>Secundario</li>
            ) : (
              ""
            )}
          </ul>
        </Row>
      </Col>
      <Col className={``} onMouseOver={mouseOver} onMouseLeave={mouseOut}>
        <Row className={`${styles.iconDownload}`}>
          {numeroDeDescargas && (
            <Col>
              <h5>{data.descargas} Descargas realizadas</h5>
            </Col>
          )}
          <FontAwesomeIcon icon={faDownload} />
          <Col className='mt-3'>
            <h5>Descargar el documento</h5>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};
