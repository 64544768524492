import { whatsAppFloatButton } from "../../assets";
import "./WhatsAppButton.scss";

export const WhatsAppButtonFloat = () => {
  const whatsAppData = `https://wa.me/${process.env.REACT_APP_WHATEL}/?text=Quiero%20información%20sobre%20sus%20servicios%20`;

  const handleWhatsAppClick = () => {
    window.dataLayer.push({
      event: "WhatsApp",
      sent: true,
    });
  };

  return (
    <div>
      <a
        href={whatsAppData}
        className='whatsapp'
        target='_blank'
        rel='noreferrer'
        onClick={handleWhatsAppClick}>
        <img alt='WhatsApp' src={whatsAppFloatButton} />
      </a>
    </div>
  );
};
