import { useEffect, useState } from "react";
import { customBreackpoint } from "../context/DataContext";

const useBreakpointValue = (breakpointName) => {
  const [breakpointValue, setBreakpointValue] = useState(null);

  useEffect(() => {
    const breakpoint = customBreackpoint.find((item) =>
      item.hasOwnProperty(breakpointName)
    );
    if (breakpoint) {
      setBreakpointValue(breakpoint[breakpointName]);
    } else {
      setBreakpointValue(`No se encontró el breakpoint ${breakpointName}`);
    }
  }, [breakpointName]);

  return breakpointValue;
};

export default useBreakpointValue;
