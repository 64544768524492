import React from "react";
import { Col, Card } from "react-bootstrap";
import styles from "./ObraCard.module.scss";

export const ObraCard = ({
  obra,
  handleClick,
  subTitle = true,
  footer = true,
}) => {
  return (
    <Col className={`h-100 ${styles.obra}`}>
      <Card className="h-100" onClick={() => handleClick(obra.ID)}>
        <Card.Img variant="top" src={obra.imgPortada} />
        <Card.Body>
          <Card.Title className={`${styles.obraTitle}`}>
            {obra.title}
          </Card.Title>
          {subTitle ? (
            <Card.Subtitle className={`mt-3`}>
              <i>{obra.subTitle}</i>
            </Card.Subtitle>
          ) : (
            ""
          )}
        </Card.Body>
        {footer ? (
          <Card.Footer className={`${styles.obrasPie}`}>
            {obra.tipoDeObra}
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>
    </Col>
  );
};
