import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Counter } from "../Counter/Counter";
import styles from "./StatCard.module.scss";
import sharedStyles from "../../scss/sharedStyles.module.scss";
import { stats } from "../../data/dataSourese";

export const StatCard = () => {
  return (
    <Row className={`mt-5 pt-5 pb-5 ${styles.cardRow}`}>
      <Container>
        <Container>
          <h3>El museo viajero en números</h3>
          <Row xs={1} md={2} lg={3} xl={5} className="g-4">
            {Array.from(stats).map((stats, id) => (
              <Col key={id} className={`${sharedStyles.float}`}>
                <Card className={`${styles.card}`}>
                  <h4>
                    <Counter
                      start={stats.start}
                      end={stats.end}
                      enableScrollSpy={stats.enableScrollSpy}
                      duration={stats.duration}
                      counterId={stats.counterId}
                      scrollSpyDelay={stats.scrollSpyDelay}
                    />
                  </h4>
                  <h6>{stats.title}</h6>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </Row>
  );
};
