import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { appFirebase } from "../../firebaseConfig/firebase";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { DataContex } from "../../context/DataContext";

export const Login = () => {
  const { userIsLogin } = useContext(DataContex);
  const auth = getAuth(appFirebase);
  const [usuario, setUsuario] = useState(null);
  const autenticasion = async (e) => {
    e.preventDefault();
    const correo = e.target.email.value;
    const password = e.target.password.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar el formato del correo electrónico

    if (!emailRegex.test(correo)) {
      console.error("El formato del correo electrónico no es válido");
      // Mostrar un mensaje de error o realizar alguna acción si el formato no es válido
      return;
    }
    try {
      // Realizar inicio de sesión si el formato del correo electrónico es válido
      await signInWithEmailAndPassword(auth, correo, password);
      // Realizar cualquier acción adicional después del inicio de sesión exitoso
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
    }
  };

  onAuthStateChanged(auth, (usuarioFirebase) => {
    if (usuarioFirebase) {
      setUsuario(usuarioFirebase);
    } else {
      setUsuario(null);
    }
  });

  const handleSignOut = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      // Realizar cualquier acción adicional después del cierre de sesión
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const [usuarioEmail, setUsuarioEmail] = useState("");

  useEffect(() => {
    if (usuario) {
      setUsuarioEmail(usuario.email);
    }
  }, [usuario]);

  return (
    <Card>
      <Card.Body>
        {userIsLogin ? (
          <Row className="justify-content-end align-items-center">
            <Col className="text-end h-100">{usuarioEmail}</Col>
            <Col md={4} className="text-end h-100">
              <Button onClick={handleSignOut}>Cerrar Sesión</Button>
            </Col>
          </Row>
        ) : (
          <Form onSubmit={autenticasion}>
            <Form.Control
              className="mb-3"
              type="email"
              placeholder="ingrese su correo"
              id="email"
            />
            <Form.Control
              className="mb-3"
              type="password"
              placeholder="ingrese su password"
              id="password"
            />
            <Button type="submit">Iniciar Sesión</Button>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
};
