import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { db } from "../../firebaseConfig/firebase";
import { useState } from "react";
import { useEffect } from "react";
import { ciclos, paths } from "../../data/dataSourese";
import { useContext } from "react";
import { DataContex } from "../../context/DataContext";
import { Login } from "../Login/Login";
import { MaterialABMForm } from "../MaterialABMForm/MaterialABMForm";

export const EditMaterial = () => {
  const { userIsLogin } = useContext(DataContex);
  const [description, setDescription] = useState("");
  const [descargas, setDescargas] = useState(0);
  const [niveles, setNiveles] = useState([]);
  const [obraID, setObraID] = useState("");
  const [tipo, setTipo] = useState("");
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const update = async (e) => {
    e.preventDefault();
    const recurso = doc(db, "recursos", id);
    const data = { description, descargas, niveles, obraID, tipo, link, title };
    await updateDoc(recurso, data);
    navigate(`/${paths.abm}`);
  };

  const getRecursoById = async (id) => {
    const recurso = await getDoc(doc(db, "recursos", id));
    if (recurso.exists()) {
      setDescription(recurso.data().description);
      setDescargas(recurso.data().descargas);
      setNiveles(recurso.data().niveles);
      setObraID(recurso.data().obraID);
      setTipo(recurso.data().tipo);
      setLink(recurso.data().link);
      setTitle(recurso.data().title);
    }
  };

  const handleCheckboxChange = (e) => {
    const nivelSeleccionado = e.target.id; // Obtiene el id del checkbox seleccionado
    if (e.target.checked) {
      setNiveles((prevNiveles) => [...prevNiveles, nivelSeleccionado]); // Agrega el nivel seleccionado al array
    } else {
      setNiveles((prevNiveles) =>
        prevNiveles.filter((nivel) => nivel !== nivelSeleccionado)
      ); // Elimina el nivel seleccionado del array
    }
  };

  useEffect(() => {
    if (id) {
      // Asegúrate de que id tenga un valor antes de llamar a getRecursoById
      getRecursoById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Row>
      <Container fluid>
        <Container>
          <h1>Material Edit</h1>
          {userIsLogin ? (
            <>
              <MaterialABMForm
                onSubmit={update}
                submitText={"Actualizar material"}
                description={description}
                setDescription={setDescription}
                descargas={descargas}
                setDescargas={setDescargas}
                ciclos={ciclos}
                niveles={niveles}
                handleCheckboxChange={handleCheckboxChange}
                obraID={obraID}
                setObraID={setObraID}
                tipo={tipo}
                setTipo={setTipo}
                link={link}
                setLink={setLink}
                title={title}
                setTitle={setTitle}
              />
            </>
          ) : (
            <Col className="mb-5 mt-5">
              <Login />
            </Col>
          )}
        </Container>
      </Container>
    </Row>
  );
};
