import { Col, Image, Button, Row, Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../data/dataSourese";
import { LottieConatiner } from "../LottieContainer/LottieConatiner";
import { confetti } from "../../assets";

import useBreakpointValue from "../../hooks/useBreakpointValue";

import styles from "./HeroSlider.module.scss";
import { DataContex } from "../../context/DataContext";
import makeHeroSlider from "../../helpers/sliderArray";

export const HeroSlider = () => {
  const { windowSize } = useContext(DataContex);

  const changeMabileData = useBreakpointValue("appSm");

  const [isImgMoblie, setIsImgMoblie] = useState(false);

  const [heroSlider, setHeroSlider] = useState([]);

  useEffect(() => {
    if (windowSize.pixelWidth <= changeMabileData) {
      setIsImgMoblie(true);
    }
  }, [changeMabileData, windowSize.pixelWidth]);

  const sheetID = process.env.REACT_APP_SHEET_ID;

  const slider = "394605623";

  const sanitizer = DOMPurify.sanitize;

  const handleLinkOpen = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    makeHeroSlider({ sheetID, slider, setHeroSlider });
  }, [sheetID]);

  const settings = {
    centerMode: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    mobileFirst: true,
    centerPadding: "200px",
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 940,
        settings: {
          arrows: false,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const SliderInLine = () => {
    return (
      <Slider {...settings} className={``}>
        {Array.from(heroSlider).map((data, id) => (
          <Col key={id} className={`${styles.itemContainer} mb-3`}>
            <Col className={`${styles.overlay} pb-5`}>
              <h4>
                <small>
                  <strong>{data.small}</strong>
                </small>
              </h4>
              <h1
                dangerouslySetInnerHTML={{
                  __html: sanitizer(data.tituloSlide),
                }}
              />
              <h4
                dangerouslySetInnerHTML={{
                  __html: sanitizer(data.subTituloSlide),
                }}
              />
              {data.cta !== "" ? (
                <Link to={`${paths.obras}/${data.cta}`}>
                  <Button style={{ zIndex: "10" }}>{data.ctaDisplay}</Button>
                </Link>
              ) : (
                ""
              )}
              {data.ctaPage !== "" ? (
                <Link to={`/${data.ctaPage}`}>
                  <Button style={{ zIndex: "10" }}>{data.ctaDisplay}</Button>
                </Link>
              ) : (
                ""
              )}
              {data.urlOutside !== "" ? (
                <Button
                  style={{ zIndex: "10" }}
                  onClick={() => handleLinkOpen(data.urlOutside)}>
                  {data.ctaDisplay}
                </Button>
              ) : (
                ""
              )}
              {data.estreno ? (
                <Col className={`${styles.estreno} h-100 `}>
                  <LottieConatiner
                    lottieJason={confetti}
                    viewerHeigth='350px'
                  />
                  <Row>
                    <Col className={`${styles.estrenoText} h-100`}>
                      <Col className={` ${styles.containerText} h-100`}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(data.estrenoText),
                          }}
                        />
                      </Col>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </Col>
            {isImgMoblie ? (
              <Image
                src={`${data.imgMobile}`}
                // rounded
                style={{ objectPosition: `${data.imgPosition}` }}
              />
            ) : (
              <Image
                src={`${data.img}`}
                // rounded
                style={{ objectPosition: `${data.imgPosition}` }}
              />
            )}
          </Col>
        ))}
      </Slider>
    );
  };

  useEffect(() => {
    if (heroSlider > 0) {
      SliderInLine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heroSlider]);

  return (
    <Row className={`${styles.silderContainer}`} id='homeHeroSlider'>
      <Container fluid>
        <SliderInLine />
      </Container>
    </Row>
  );
};
