import { Card, Col, Container, Row } from "react-bootstrap";
import { Repertorio } from "../Repertorio/Repertorio";
import { paths, stats } from "../../data/dataSourese";
import { Counter } from "../Counter/Counter";
import styles from "./Pages.module.scss";
import sharedStyles from "../../scss/sharedStyles.module.scss";
import counterStyle from "../StatCard/StatCard.module.scss";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import Helmet from "../Helmet";
export const RepertorioPage = () => {
  return (
    <>
      <Helmet
        title={`Nuestro Repertório`}
        link={`/${paths.obras}`}
        location={{}}
      />
      <Row className={` ${styles.repertorioPage}`}>
        <Container fluid className={`${styles.heather} pt-3 pb-3`}>
          <Container>
            <Row className={`g-5 `}>
              <Col md={3} className={`${sharedStyles.float}`}>
                <Card className={`${counterStyle.card}`}>
                  <h4>
                    <Counter
                      start={stats[2].start}
                      end={stats[2].end}
                      enableScrollSpy={stats[2].enableScrollSpy}
                      duration={stats[2].duration}
                      counterId={stats[2].counterId}
                      scrollSpyDelay={stats[2].scrollSpyDelay}
                    />
                  </h4>
                  <h6>{stats[2].title}</h6>
                </Card>
              </Col>
              <Col className={`${styles.title}`}>
                <Row className='align-content-center h-100'>
                  <Col>
                    <h2>Nuestro repertorio</h2>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className={`${styles.page}`}>
          <Repertorio />
        </Container>
        <Col>
          <ScrollPercentage maxScroll={20} />
        </Col>
      </Row>
    </>
  );
};
