import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { appFirebase, db } from "../../firebaseConfig/firebase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { paths } from "../../data/dataSourese";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Login } from "../Login/Login";

import sahredStyles from "../../scss/sharedStyles.module.scss";
import { IdToTitle } from "../IdToTitle/IdToTitle";
import { DataContex } from "../../context/DataContext";

export const MaterialABM = () => {
  const { obrasId } = useContext(DataContex);
  const navigate = useNavigate();
  const auth = getAuth(appFirebase);
  const [usuario, setUsuario] = useState(null);
  const [recursos, setRecursos] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setUsuario(usuarioFirebase);
      } else {
        setUsuario(null);
      }
    });

    // Lectura inicial
    const fetchData = async () => {
      const data = await getDocs(collection(db, "recursos"));
      setRecursos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchData();

    // Agregar un event listener para el evento popstate
    const handlePopstate = () => {
      // Modificar el historial cuando se detecta un cambio de estado de navegación
      navigate("/home", { replace: true });
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      unsubscribe(); // Desuscribirse cuando el componente se desmonte
      window.removeEventListener("popstate", handlePopstate); // Quitar el event listener
    };
  }, [auth, navigate]);

  const handleClick = (url) => {
    navigate(`/${url}`);
  };

  const MySwal = withReactContent(Swal);

  const recusrsosCollection = collection(db, "recursos");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getRecursos = async () => {
    const data = await getDocs(recusrsosCollection);

    setRecursos(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteRecurso = async (id) => {
    const recursoDoc = doc(db, "recursos", id);
    await deleteDoc(recursoDoc);
    getRecursos();
  };

  const confirmDelete = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRecurso(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const handleViewFile = (pdfLink) => {
    window.open(`${pdfLink}`, "_blank");
  };

  useEffect(() => {
    getRecursos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Container fluid>
        <Container style={{ position: "relative" }}>
          <Col
            style={{
              zIndex: "10",
              position: "fixed",
              right: "20%",
              top: "60px",
              border: "1px solid black",
            }}></Col>
        </Container>
        {usuario ? (
          <Container>
            <Navbar
              sticky='top'
              style={{
                paddingTop: "120px",
                marginTop: "-110px",
                zIndex: "0",
              }}>
              <Col>
                <Button
                  variant='primary'
                  onClick={() => handleClick(paths.materialCreate)}>
                  Nuevo material
                </Button>
              </Col>
              <Col md={4}>
                <Login />
              </Col>
            </Navbar>
            <div className='mt-3'>
              {recursos.map((recurso, id) => (
                <li style={{ listStyle: "none", backgroundColor: "" }} key={id}>
                  <Row className='mb-5'>
                    <Col md={12}>
                      <h4>
                        {recurso.description} | {recurso.tipo}{" "}
                      </h4>
                      <p>
                        Obra Relacionada:{" "}
                        <b>
                          {" "}
                          <IdToTitle id={recurso.obraID} data={obrasId} />
                        </b>
                      </p>
                      <p>{recurso.descargas} descargas</p>
                    </Col>
                    <Col>
                      <Button
                        variant='primary'
                        onClick={() =>
                          navigate(`/${paths.editMaterial}/${recurso.id}`)
                        }>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => {
                          confirmDelete(recurso.id);
                        }}>
                        <FontAwesomeIcon icon={faTrashCan} />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() =>
                          handleViewFile(recurso.link, recurso.id)
                        }>
                        Ver Archivo
                      </Button>
                    </Col>
                    <Row
                      className={`mt-3 mb-3`}
                      style={{ margin: "0 px 0 0px" }}>
                      {Array.from(recurso.niveles).map((data, id) => (
                        <Col
                          key={id}
                          md={2}
                          className={`${sahredStyles.ciclo}`}>
                          {data}
                        </Col>
                      ))}
                    </Row>
                  </Row>
                </li>
              ))}
            </div>
          </Container>
        ) : (
          <Container>
            <Login />
          </Container>
        )}
      </Container>
    </Row>
  );
};
