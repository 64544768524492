import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";

import "./navBar.scss";

import styles from "./NavBar.module.scss";
import { DataContex } from "../../context/DataContext";
import { pushToArray } from "../../helpers/pushToArray";
import { LogoMuseoCajaH } from "../../assets/logoMuseoCajaV";
import { IsoMuseo } from "../../assets/isoMuseo";
import { obtenerObrasUnicasPorPropiedad } from "../../helpers/obrasUnicasPorTema";
import useBreakpointValue from "../../hooks/useBreakpointValue";

export const NavBar = ({ expand }) => {
  const {
    windowSize,
    navBarNavigation,
    dataSource: data,
  } = useContext(DataContex);

  const [pageScorlled, setPageScorlled] = useState(false);
  const [pathAcual, setPathAcual] = useState("/");
  const [linkActivo, setLinkActivo] = useState(false);
  const [idObraActiva, setIdObraActiva] = useState("");
  const [show, setShow] = useState(false);
  const location = useLocation();

  window.addEventListener("scroll", () => {
    if (window.scrollY > 10) {
      setPageScorlled(true);
    } else {
      setPageScorlled(false);
    }
  });

  const goToTop = () => {
    if (pageScorlled) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    goToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setPathAcual(location.pathname);
    if (pathAcual.includes("/obras")) {
      setLinkActivo(true);
    } else {
      setLinkActivo(false);
    }
    if (linkActivo === true) {
      setIdObraActiva("obras");
    } else {
      setIdObraActiva("");
    }
  }, [location.pathname, pathAcual, linkActivo]);

  const [navData, setnavData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [temario, setTemario] = useState([]);

  const obtenerObras = (array) => {
    return array.filter((item) => item.obras).map((item) => item.obras);
  };

  useEffect(() => {
    setnavData(pushToArray(navBarNavigation, dataSource, "Obras"));
    setDataSource(data);
    const newData = pushToArray(navBarNavigation, dataSource, "Obras");
    const obras = obtenerObras(newData);
    setTemario(obtenerObrasUnicasPorPropiedad(obras));
  }, [data, dataSource, linkActivo, navBarNavigation]);
  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
    if (windowSize.windowsName === "appMd") {
      setIsXs(true);
    }
    if (windowSize.windowsName === "appSm") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  const toggleOffCanvas = () => {
    if (isXs) {
      setShow((show) => !show);
    } else {
      setShow(false);
    }
  };

  return (
    <Navbar
      key={expand}
      expand={expand}
      className={`bg-body-tertiary  ${styles.mainNavBar}`}
      sticky="top"
    >
      <Container>
        <NavLink to={"/"}>
          <Navbar.Brand className={styles.customBrand}>
            {windowSize.pixelWidth <= useBreakpointValue("appXs") ? (
              <IsoMuseo color="#5F9EA1" logoHeight="60" />
            ) : (
              ""
            )}
            {windowSize.pixelWidth >= useBreakpointValue("appSm") ? (
              <LogoMuseoCajaH
                color="#5F9EA1"
                logoHeight="120"
                logoWidth="200"
              />
            ) : (
              ""
            )}
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-${expand}`}
          onClick={toggleOffCanvas}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
          show={show}
          onHide={toggleOffCanvas}
        >
          <Offcanvas.Header closeButton style={{ color: "#fff !important" }}>
            <Offcanvas.Title
              className={styles.customBrand}
              id={`offcanvasNavbarLabel-expand-${expand}`}
            >
              <LogoMuseoCajaH color="white" logoHeight="120" logoWidth="200" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav
              className={`justify-content-end flex-grow-1 pe-3 ${styles.customOffCanvas}`}
            >
              {navData.map((item, id) => (
                <div key={id} className={styles.customNavLink}>
                  {item.obras ? (
                    <NavDropdown title={item.text} id={`${idObraActiva}`}>
                      <NavDropdown.Item
                        as={NavLink}
                        to={`${item.link}`}
                        className={linkActivo ? styles.navActive : ""}
                        onClick={toggleOffCanvas}
                      >
                        Repertorio
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      {Array.from(temario).map((data, id) => (
                        <NavDropdown key={id} title={data}>
                          {Array.from(item.obras).map((item, id) => (
                            <Col
                              key={id}
                              className={`${styles.subMenuContainer}`}
                            >
                              {data === item.temaObra ? (
                                <NavDropdown.Item
                                  className={`${styles.subMenu}`}
                                  as={NavLink}
                                  to={`/obras/${item.ID}`}
                                  onClick={toggleOffCanvas}
                                >
                                  {item.title}
                                </NavDropdown.Item>
                              ) : (
                                ""
                              )}
                            </Col>
                          ))}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                  ) : (
                    <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                        isActive ? styles.navActive : ""
                      }
                      onClick={toggleOffCanvas}
                    >
                      {item.text}
                    </NavLink>
                  )}
                </div>
              ))}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};
