import { Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { DataContex } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { temasObrasArray } from "../../data/dataSourese";
import { ObraCard } from "./ObraCard";

import styles from "./Repertorio.module.scss";
import useBreakpointValue from "../../hooks/useBreakpointValue";

export const Repertorio = () => {
  const { dataSource: data, paths, windowSize } = useContext(DataContex);

  const navigate = useNavigate();

  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}/${obraElegida}`);
  };

  const buscarPorTemaObra = (tema) => {
    return data.filter((obra) => {
      return obra.temaObra === tema;
    });
  };

  const breakpointSm = useBreakpointValue("appSm");

  const [isXs, setIsXs] = useState(true);

  useEffect(() => {
    if (windowSize && windowSize.pixelWidth) {
      setIsXs(false); // Reemplaza 576 por el valor deseado para 'appSm'
    }
  }, [breakpointSm, windowSize]);

  return (
    <Container className={`mt-5 ${styles.obrasPara}`}>
      {Array.from(temasObrasArray).map((tema, id) => (
        <Col key={id} className={`${styles.obra}`}>
          {buscarPorTemaObra(tema.temaDisplay).length > 0 ? (
            <>
              <h3>
                <span className={`${styles.cantidadDeObras}`}>
                  {buscarPorTemaObra(tema.temaDisplay).length}{" "}
                </span>
                {buscarPorTemaObra(tema.temaDisplay).length > 1 ? (
                  <span> obras </span>
                ) : (
                  <span> obra </span>
                )}
                {isXs ? <br /> : ""}
                {tema.temaDisplay}
              </h3>

              <hr />

              <Row xs={1} md={4} xxl={5} className='g-4 mb-5'>
                {Array.from(buscarPorTemaObra(tema.temaDisplay)).map(
                  (obra, id) => (
                    <Col key={id}>
                      <ObraCard obra={obra} id={id} handleClick={handleClick} />
                    </Col>
                  )
                )}
              </Row>
            </>
          ) : (
            ""
          )}
        </Col>
      ))}
    </Container>
  );
};
