import { useContext } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { DataContex } from "../../context/DataContext";
import styles from "./ContenidoCartelera.module.scss";
import { teatro } from "../../assets";
import { ObraEnCartelera } from "./ObraEnCartelera";

export const ContenidoCartelra = ({ props }) => {
  const { isLoading, arrayAgrupado: carteleraData } = useContext(DataContex);

  // console.log(carteleraData);
  return (
    <>
      {carteleraData.length !== 0 ? (
        <Container fluid>
          <Container>
            <Row xs={1} md={2} lg={4} className='g-4 pt-1 pb-3'>
              {isLoading ? (
                <h1
                  style={{
                    height: "250px",
                    width: "100%",
                    backgroundColor: "red",
                  }}>
                  Cartelera
                </h1>
              ) : (
                <>
                  {Array.from(carteleraData).map((data, id) => (
                    <ObraEnCartelera key={id} data={data} id={id} />
                  ))}
                </>
              )}
            </Row>
          </Container>
        </Container>
      ) : (
        <Row className={`${styles.imgConatiner}`}>
          <Image src={teatro} fluid />
          <Col xs={12} className={`${styles.overLay}`}>
            <h2>
              Lo sentimos
              <br />
              no encontramos obras en cartel
              <br />
              en este momento
            </h2>
          </Col>
        </Row>
      )}
    </>
  );
};
