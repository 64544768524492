import { useCountUp } from "react-countup";

export const Counter = ({
  start = 0,
  end = 100,
  enableScrollSpy = true,
  scrollSpyDelay = 500,
  counterId = "conter",
  duration = 2000,
  scrollSpyOnce = false,
}) => {
  useCountUp({
    ref: counterId,
    start,
    end,
    enableScrollSpy,
    scrollSpyDelay,
    duration,
    decimal: ",",
    separator: ".",
    scrollSpyOnce,
  });

  return <span id={`${counterId}`} />;
};
