import { Button, Image, Modal } from "react-bootstrap";

import styles from "./Modal.module.scss";

export const MyModal = ({ show, handleClose, url, ariaLabel, size = "lg" }) => {
  return (
    <Modal
      className={`${styles.modalContent}`}
      id="modalMain"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={size}
      aria-labelledby={ariaLabel}
      centered
      fullscreen={false}
    >
      <Image src={url} />
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
