import Papa from 'papaparse';

const makeHeroSlider = async ({ sheetID, slider, setHeroSlider }) => {
    const url = `https://docs.google.com/spreadsheets/d/${sheetID}/export?gid=${slider}&format=csv`;

    try {
        // Convertimos la llamada a Papa.parse en una Promesa
        const parseCsv = (url) => {
            return new Promise((resolve, reject) => {
                Papa.parse(url, {
                    download: true,
                    header: true,
                    complete: (results) => {
                        resolve(results.data);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
            });
        };

        const data = await parseCsv(url);

        const obrasRecursosConBooleanos = data.map((item) => ({
            ...item,
            estreno: item.estreno === "TRUE",
            visible: item.visible.toLowerCase() === "si"
        }));

        const arrayFiltrado = obrasRecursosConBooleanos.filter(item => item.visible);

        setHeroSlider(arrayFiltrado);
    } catch (error) {
        console.error("Error al procesar el slider de héroes:", error);
        // Manejo adicional de errores, si es necesario.
    }
};

export default makeHeroSlider;
