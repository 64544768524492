import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { paths } from "../../data/dataSourese";
import { useContext } from "react";
import { DataContex } from "../../context/DataContext";
import { storage } from "../../firebaseConfig/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const MaterialABMForm = ({
  onSubmit,
  submitText,
  description,
  setDescription,
  descargas,
  setDescargas,
  ciclos,
  niveles,
  handleCheckboxChange,
  obraID,
  setObraID,
  tipo,
  setTipo,
  link,
  setLink,
  title,
  setTitle,
}) => {
  const { obrasId, tipoDeMaterial, categoriasRecursosSource } =
    useContext(DataContex);

  const [fileIsReady, setFileIsReady] = useState(false);

  useEffect(() => {
    if (link === null || link === undefined || link === "") {
      setFileIsReady(false);
    } else {
      setFileIsReady(true);
    }
  }, [link]);

  const ordenarArrayPorPropiedad = (array, propiedad) => {
    return array.slice().sort((a, b) => {
      const valorA = a[propiedad].toUpperCase();
      const valorB = b[propiedad].toUpperCase();

      if (valorA < valorB) {
        return -1;
      }
      if (valorA > valorB) {
        return 1;
      }
      return 0;
    });
  };

  function uploadFile(file, fileName, folder) {
    const storageRef = ref(storage, folder + "/" + fileName); // Define la referencia con la carpeta y el nombre del archivo
    uploadBytes(storageRef, file)
      .then(async (snapshot) => {
        const downloadURL = await getDownloadURL(snapshot.ref);
        setLink(downloadURL);
        // Aquí puedes realizar acciones adicionales con la URL obtenida
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
  }
  const handleClickFile = () => {
    window.open(link, "_blnak");
  };

  return (
    <Form onSubmit={onSubmit}>
      <Container>
        <Row className={`mb-3`}>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Descargas</Form.Label>
              <Form.Control
                type="number"
                value={descargas}
                required
                onChange={(e) => setDescargas(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Niveles</Form.Label>
          <Row style={{ padding: "12px" }}>
            {Array.from(ciclos).map((data, id) => (
              <Col key={id} className="mb-3 form-check form-switch">
                <input
                  checked={niveles.includes(`${data}`)}
                  onChange={handleCheckboxChange}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={data}
                />
                <label className="form-check-label" htmlFor="inicial">
                  {data}
                </label>
              </Col>
            ))}
          </Row>
        </Form.Group>
        <Form.Group>
          <Row className="mb-5">
            <Col>
              <Form.Label>Tema Relacionado:</Form.Label>
            </Col>
            <Row>
              <Col>
                <Form.Select
                  size="md"
                  value={title} // Valor seleccionado
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  required // Actualiza el estado al seleccionar una opción
                >
                  <option value="" disabled>Selecciona un Tema</option>
                  {Array.from(
                    ordenarArrayPorPropiedad(
                      categoriasRecursosSource,
                      "temaRelacionado"
                    )
                  ).map((data, id) => (
                    <option key={id} value={data.ID} type="text">
                      {data.temaRelacionado}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                Tema relacionado: <b>{title}</b>
              </Col>
            </Row>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Obra asociada</Form.Label>
              <Row>
                <Col>
                  <Form.Select
                    size="md"
                    value={obraID} // Valor seleccionado
                    onChange={(e) => {
                      setObraID(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled>Selecciona una opción</option>
                    {Array.from(ordenarArrayPorPropiedad(obrasId, "title")).map(
                      (data, id) => (
                        <option key={id} value={data.ID} type="text">
                          {data.title}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Col>
                <Col>
                  Obra ID: <b>{obraID}</b>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Label>Tipo de material</Form.Label>
              <Row>
                <Col>
                  <Form.Select
                    size="md"
                    value={tipo} // Valor seleccionado
                    onChange={(e) => setTipo(e.target.value)} // Actualiza el estado al seleccionar una opción
                    required
                  >
                    <option value="" disabled>Selecciona un Tipo</option>
                    {Array.from(tipoDeMaterial).map((data, id) => (
                      <option key={id} value={data} type="text">
                        {data}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>{tipo}</Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mt-3 mb-3">
              <Form.Control
                type="file"
                id=""
                onChange={(e) =>
                  uploadFile(e.target.files[0], description, "/pdfFile/")
                }
                required
              />
              {fileIsReady ? (
                <>
                  <Row className="mt-3 mb-3" style={{ padding: "12px" }}>
                    <Form.Label>Archivo actual</Form.Label>
                    <Button onClick={handleClickFile}>Ver Archivo</Button>
                  </Row>
                </>
              ) : (
                ""
              )}
            </Form.Group>
          </Row>
        </Form.Group>
        <Row className={`mt-5 mb-5`}>
          <Col>
            <Button type="submit" variant="primary" style={{ width: "100%" }}>
              {submitText}
            </Button>
          </Col>
          <Col>
            <Row>
              <a href={`/${paths.abm}`}>
                <Button style={{ width: "100%" }} variant="danger">
                  Cancelar
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};
