import { initializeApp } from "firebase/app";

import { getFirestore } from "@firebase/firestore";

import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "museoviajero.firebaseapp.com",
  projectId: "museoviajero",
  storageBucket: "museoviajero.appspot.com",
  messagingSenderId: "952519393232",
  appId: "1:952519393232:web:6ab2260c8d9cb40fed83d5",
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);
console.log("Firebase initialized successfully!"); // Agregar este console.log
const storage = getStorage(appFirebase);
const db = getFirestore(appFirebase);

export { db, appFirebase, storage };
