import { Container } from "react-bootstrap";
import "./App.scss";
import { NavBar } from "./components/NavBar/NavBar";
import { Navigate, Route, Routes } from "react-router-dom";
import { useContext } from "react";
import { DataContex } from "./context/DataContext";
import { CarteleraPage } from "./components/pages/CarteleraPage";
import { MaterialesPage } from "./components/pages/MaterialesPage";
import { RepertorioPage } from "./components/pages/RepertorioPage";
import { Footer } from "./components/Footer/Footer";
import { brandCajaH } from "./assets";
import { ContactoPage } from "./components/pages/ContactoPage";
import { ObraInfo } from "./components/Repertorio/ObraInfo";
import { NosotrosPage } from "./components/pages/NosotrosPage";
import { PublicacionesPage } from "./components/pages/PublicacionesPage";
import { MaterialABM } from "./components/MaterialABM/MaterialABM";
import { EditMaterial } from "./components/MaterialEdit/EditMaterial";
import { MaterialCreate } from "./components/MaterialCreate/MaterialCreate";
import { HomePage } from "./components/pages/HomePage";
import { Prensa } from "./components/pages/Prensa";
import { WhatsAppButtonFloat } from "./components/WhatsAppButton/WhatsAppFloatButton";
import RepertorioPorTema from "./components/Repertorio/RepertorioPorTema";
import { DiarioDeMarcha } from "./components/pages/DiarioDeMarcha";
import { CalculadorDeFuncion } from "./components/pages/CalculadorDeFuncion";

function App() {
  const { paths, dataSource } = useContext(DataContex);

  const {
    cartelera,
    home,
    obras,
    materiales,
    contacto,
    nosotros,
    publicaciones,
    editMaterial,
    materialCreate,
    abm,
    prensa,
    diarioDeMarcha,
    calculador
  } = paths;

  return (
    <Container fluid className='App'>
      <NavBar brand={brandCajaH} expand={"lg"} />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path={home} element={<HomePage />} />
        <Route path={cartelera} element={<CarteleraPage />} />
        <Route path={materiales} element={<MaterialesPage />} />
        <Route path={publicaciones} element={<PublicacionesPage />} />
        <Route path={obras} element={<RepertorioPage />} />
        <Route path={contacto} element={<ContactoPage />} />
        <Route path={nosotros} element={<NosotrosPage />} />
        <Route path={prensa} element={<Prensa />} />
        <Route path={`${editMaterial}/:id`} element={<EditMaterial />} />
        <Route path={materialCreate} element={<MaterialCreate />} />
        <Route path={abm} element={<MaterialABM />} />
        <Route path={diarioDeMarcha} element={<DiarioDeMarcha />} />
        <Route path={calculador} element={<CalculadorDeFuncion />} />
        <Route path={`${obras}/tema/:tema`} element={<RepertorioPorTema />} />
        {
          // Obras Info Pages
          Array.from(dataSource).map((obra, id) => (
            <Route
              key={id}
              path={`${obras}/${obra.ID}`}
              element={<ObraInfo obra={obra} />}
            />
          ))
        }
        <Route path='/*' element={<Navigate to={paths.home} replace />} />
      </Routes>
      <Footer />
      <WhatsAppButtonFloat />
    </Container>
  );
}

export default App;

//http://localhost:3000/static/media/aldeaPortada.f4ba853de677786dc5d8.webp
