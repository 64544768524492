import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ContactUs } from "../ContactUs/ContactUs";
import { contact } from "../../assets";
import { paths } from "../../data/dataSourese";
import styles from "./ContactoPage.module.scss";
import Helmet from "../Helmet";

export const ContactoPage = () => {
  return (
    <>
      <Helmet title={"Contáctenos"} link={`/${paths.contacto}`} location={{}} />
      <Container>
        <Row
          className={`${styles.formSection} justify-content-center mt-5 mb-5`}>
          <Col lg={9}>
            <Row className={`${styles.imageFormContainer} `}>
              <Col md={5} className='order-sm-1'>
                <Row>
                  <Col xs={12} className={`${styles.formTitel} mt-4`}>
                    <h3>Contatate con nosotros</h3>
                  </Col>
                  <Col className='d-flex' style={{ height: "minContent" }}>
                    <ContactUs />
                  </Col>
                </Row>
              </Col>
              <Col className='order-sm-0'>
                <Image fluid src={contact} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
