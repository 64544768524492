import React, { useState, useEffect } from "react";
import { findImage } from "../../helpers/linkPreviewUtils"; // Importamos la función findImage desde un archivo externo
import { Image } from "react-bootstrap";
import { placeholderImg } from "../../assets";

function LinkPreview({ url, fallbackImage = placeholderImg }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [altText, setAltText] = useState("");

  useEffect(() => {
    const fetchLinkPreviewData = async () => {
      const serverUrl = "https://museo-bakend.vercel.app"; // "http://localhost:3001"
      const path = "/fetch-url?url=";
      const backendUrl = `${serverUrl}${path}`;
      try {
        const response = await fetch(backendUrl + encodeURIComponent(url));
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const { metaTags } = await response.json();
        // Llamamos a la función findImage para encontrar la URL de la imagen en los metaTags
        const imageUrl = findImage(metaTags, fallbackImage, setAltText);
        setImageUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching link preview data:", error);
        setImageUrl(fallbackImage);
      }
    };

    fetchLinkPreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, fallbackImage]);

  return (
    <>
      {imageUrl ? (
        <Image src={imageUrl} alt={altText} />
      ) : (
        <Image src={fallbackImage} alt='Fallback Image' />
      )}
    </>
  );
}

export default LinkPreview;
