import { Button, Col, Container, Row } from "react-bootstrap";

import { ContenidoCartelra } from "../ContenidoCartelera/ContenidoCartelra";
import { ObrasPorMes } from "../ObrasPorMes/ObrasPorMes";
import { paths } from "../../data/dataSourese";
import { useNavigate } from "react-router-dom";

import styles from "./Pages.module.scss";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import Helmet from "../Helmet";

export const CarteleraPage = () => {
  const navigate = useNavigate();

  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}`);
  };

  return (
    <>
      <Helmet
        title={`Obras en cartel`}
        description={"Podes encontrar las funciones programadas"}
        link={`/${paths.cartelera}`}
        location={{}}
      />

      <Row className={`${styles.page}`}>
        <Container className='mt-5  mb-1'>
          <Container>
            <h1>Ahora en cartelera</h1>
          </Container>
        </Container>
        <ContenidoCartelra />
        <Container fluid>
          <Container>
            <Row className={`justify-content-center mt-5`}>
              <Col md={4} className='d-grid' onClick={handleClick}>
                <Button size='lg'>Ver todas nuestras obras</Button>
              </Col>
            </Row>
          </Container>
        </Container>
        <ObrasPorMes mesesAMostrar={9} />
        <ScrollPercentage maxScroll={20} />
      </Row>
    </>
  );
};
