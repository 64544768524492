import { Col, Container, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Footer.module.scss";
import { footerData, socialMedia } from "../../data/dataSourese";
import { ContactButton } from "./ContactButton";
import moment from "moment";
import { apsis } from "../../assets";

export const Footer = () => {
  const anio = moment();
  const anioToinsert = anio.format("YYYY");

  return (
    <footer>
      <Container fluid className={`${styles.footerCont}`}>
        <Container className={`${styles.contact}`}>
          <Row className={`${styles.contacContainer}  g-4`}>
            {Array.from(footerData.contactChanels).map(
              ({ buttonIcon, label, link }, id) => (
                <ContactButton
                  key={id}
                  buttonIcon={buttonIcon}
                  label={label}
                  link={link}
                />
              )
            )}
          </Row>
        </Container>
        <Container fluid className={`${styles.subFooterContent}`}>
          <Container>
            <Row className={`justify-content-between `}>
              <Col xs={12} md={4} className={`${styles.socialLinks}`}>
                {Array.from(socialMedia).map((data, id) => (
                  <a key={id} href={data.link} target="_blamck">
                    <FontAwesomeIcon icon={data.fontAwesome} size="2xl" />
                  </a>
                ))}
              </Col>
              <Col xs={12} md={4} className={`${styles.copy}`}>
                <small>
                  <strong>El Museo Viajero</strong> &copy;{`  ${anioToinsert}`}
                </small>
              </Col>
              <Col xs={12} md={4} className={`${styles.apsis}`}>
                <span>
                  <a
                    href="https://apsis.com.ar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Powered by <Image src={apsis} />
                  </a>
                </span>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </footer>
  );
};
