import { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { DataContex } from "../../context/DataContext";

export const BannerTopPages = ({
  img,
  positionY,
  margBottom,
  positionX = "center",
}) => {
  const { windowSize } = useContext(DataContex);

  const [bannerHeight, setBannerHeight] = useState("45vh");

  useEffect(() => {
    switch (windowSize.windowsName) {
      case "appXs":
        setBannerHeight("40vh");
        break;
      case "appSm":
        setBannerHeight("40vh");
        break;
      case "appMd":
        setBannerHeight("30vh");
        break;
      case "appLg":
        setBannerHeight("30vh");
        break;
      case "appXxl":
        setBannerHeight("50vh");
        break;
      case "appFull":
        setBannerHeight("50vh");
        break;
      case "app4k":
        setBannerHeight("50vh");
        break;
      // Otros casos para cada breakpoint
      default:
        // Establece un valor predeterminado si el nombre del breakpoint no coincide con ninguno
        setBannerHeight("45vh");
    }
  }, [windowSize]);

  return (
    <Row
      style={{
        minHeight: `${bannerHeight}`,
        background: `no-repeat url("${img}")`,
        backgroundSize: "cover",
        backgroundPositionY: `${positionY}`,
        backgroundPositionX: `${positionX}`,
        marginBottom: `${margBottom}`,
      }}
    ></Row>
  );
};
