import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button, Form, Row, Col, Container } from "react-bootstrap";

import styles from "./ContactUs.module.scss";

export const ContactUs = () => {
  const form = useRef();
  const [emailMatch, setEmailMatch] = useState(true);

  const MySwal = withReactContent(Swal);

  const formOk = () => {
    MySwal.fire({
      title: "Mensaje Enviado",
      text: "Su mensaje fue enviado correctamente, nos comunicaremos a la brebedad",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Listo",
    });
  };

  const formError = () => {
    MySwal.fire({
      title: "Upss !!!",
      text: "No hemos podido enviar su mensaje",
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Listo",
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      form.current.user_email.value !== form.current.confirm_user_email.value
    ) {
      setEmailMatch(false);
      return;
    }

    setEmailMatch(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
      )
      .then((result) => {
        if (result.status === 200) {
          form.current.reset(); // Reiniciar el formulario si la respuesta es exitosa (código 200)
          formOk();
        }
      })
      .catch((error) => {
        console.log(error.text);
        formError();
        form.current.reset();
      });
  };

  return (
    <Form
      id="contact-form"
      ref={form}
      onSubmit={sendEmail}
      className={`${styles.contacUs} mb-5`}
    >
      <Form.Group controlId="user_institution">
        <Form.Label>Institución educativa a la que pertenece</Form.Label>
        <Form.Control
          type="text"
          name="user_institution"
          placeholder="Institución educativa"
          required
        />
      </Form.Group>

      <Form.Group className="mt-3" controlId="user_name">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          type="text"
          name="user_name"
          placeholder="Ingrese su nombre"
          required
        />
      </Form.Group>

      <Row className="mt-3">
        <Form.Group as={Col} xs={12} controlId="user_email">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
            type="email"
            name="user_email"
            placeholder="Ingrese su correo electrónico"
            required
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} controlId="confirm_user_email">
          <Form.Label>Confirmar correo</Form.Label>
          <Form.Control
            type="email"
            name="confirm_user_email"
            placeholder="Ingrese su correo electrónico nuevamente"
            required
            isInvalid={!emailMatch}
          />
          {!emailMatch && (
            <Form.Control.Feedback type="invalid">
              Los correos electrónicos no coinciden.
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>

      <Row>
        <Row className="mt-3">
          <Container>
            <Form.Label>Dejanos tu WhatsApp</Form.Label>
          </Container>
        </Row>
        <Form.Group as={Col} controlId="phone_country_code">
          <Form.Label>País</Form.Label>
          <Form.Control
            type="text"
            name="phone_country_code"
            pattern="\+54"
            placeholder="+54"
            defaultValue="+54"
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="phone_area_code">
          <Form.Label>Área</Form.Label>
          <Form.Control
            type="text"
            name="phone_area_code"
            pattern="[0-9]{2,4}"
            placeholder="Ej: 011"
            required
          />
        </Form.Group>

        <Form.Group as={Col} md={6} controlId="user_phone">
          <Form.Label>Número</Form.Label>
          <Form.Control
            type="tel"
            name="user_phone"
            pattern="[0-9]{6,10}"
            placeholder="Número de teléfono"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor, ingrese un número de teléfono válido.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group className="mt-3" controlId="message">
        <Form.Label>Mensaje</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          placeholder="Escriba su mensaje aquí"
          required
        />
      </Form.Group>
      <Row>
        <Col>
          <Button className="mt-3" type="submit">
            Enviar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
