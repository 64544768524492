import { Container } from "react-bootstrap";

export const TituloSeccion = ({ titulo, id }) => {
  return (
    <Container fluid id={id}>
      <Container className={`text-center mt-5`}>
        <h3>{titulo}</h3>
      </Container>
    </Container>
  );
};
