import { Col } from "react-bootstrap";

import styles from "./VideoLoader.module.scss";

export const VideoLoader = ({ videoId }) => {
  return (
    <Col className={`${styles.container}`}>
      <iframe
        width='1920'
        height='1080'
        src={`https://www.youtube.com/embed/${videoId}?&rel=0`}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
        className={`${styles.video}`}></iframe>
    </Col>
  );
};
