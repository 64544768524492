import { Col, Container, Row } from "react-bootstrap";
import DOMPurify from "dompurify";
import { nosotrosData, paths, sponsors } from "../../data/dataSourese";
import { elenco } from "../../assets";
import { BannerTopPages } from "../BannerTopPages/BannerTopPages";
import styles from "./NosotrosPage.module.scss";
import { SponsorSlider } from "../SponsorSlider/SponsorSlider";
import { useContext, useEffect, useState } from "react";
import { DataContex } from "../../context/DataContext";
import { TimeLine } from "../TimeLine/TimeLine";
import { TituloSeccion } from "../TituloSeccion/TituloSeccion";
import { useLocation } from "react-router-dom";
import Helmet from "../Helmet";

export const NosotrosPage = () => {
  const { windowSize } = useContext(DataContex);

  const sanitizer = DOMPurify.sanitize;

  const location = useLocation();

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  useEffect(() => {
    // Verificar si hay un fragmento en la URL
    const hasFragment = location.hash.length > 0;

    // Si hay un fragmento, intentar desplazarse a él
    if (hasFragment) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = 110; // Ajusta este valor según sea necesario
        const y =
          element.getBoundingClientRect().top + window.pageYOffset - yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet title={"Nosotros"} link={`/${paths.nosotros}`} location={{}} />
      <Container fluid>
        {isXs ? (
          <BannerTopPages img={elenco} positionY={"30%"} margBottom={"-30%"} />
        ) : (
          <BannerTopPages img={elenco} positionY={"30%"} margBottom={"-5%"} />
        )}
        <Container>
          <Row className={`justify-content-center`}>
            <Col lg={8} className={`${styles.main}`}>
              <h1>{nosotrosData.title}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizer(nosotrosData.body),
                }}
              />
            </Col>
          </Row>
          <TituloSeccion titulo={"Cronología"} id={"crono"} />
          <TimeLine />
        </Container>
      </Container>
      <SponsorSlider slides={sponsors} />
    </>
  );
};
