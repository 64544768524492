export const pushToArray = (dataDestino, dataFuente, destino) => {
  const dataArray = dataFuente.map((item) => ({
    ID: item.ID,
    title: item.title,
    temaObra: item.temaObra,
  }));

  const dataDestinoActualizado = [...dataDestino];
  const destinoIndex = dataDestinoActualizado.findIndex(
    (item) => item.text === destino
  );

  if (destinoIndex !== -1) {
    dataDestinoActualizado[destinoIndex][destino.toLowerCase()] = dataArray;
  }

  return dataDestinoActualizado;
};
