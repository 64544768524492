import { Container } from "react-bootstrap";
import { libroAldea, libroComedias01, libroComedias02 } from "../../assets";
import { Publicacion } from "../Publicacion/Publicacion";
import { ScrollPercentage } from "../ScrollPercentage/ScrollPercentage";
import Helmet from "../Helmet";
import { paths, publicaciones } from "../../data/dataSourese";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const PublicacionesPage = () => {
  const { obrasIncluidas01, obrasIncluidas02, obrasIncluidasAldea } =
    publicaciones;

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offsetTop =
          element.getBoundingClientRect().top + window.pageYOffset;
        // const fixedHeaderHeight = 560; // Ajusta este valor al alto de tu cabecera
        window.scrollTo({
          top: offsetTop, //  - fixedHeaderHeight
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet
        title={"Publicaciones"}
        link={`/${paths.publicaciones}`}
        location={{}}
      />
      <Container>
        <Publicacion
          tituloPublicacion='COMEDIAS DEL MUSEO VIAJERO 1'
          imagenPublicacion={libroComedias01}
          obrasIncluidas={obrasIncluidas01}
          linkPublicacion='https://www.eudeba.com.ar/Papel/9789502333427/Comedias+del+Museo+Viajero+1'
          infoPublicacion='El Museo Viajero es una compañía de teatro e investigación histórica fundada en 1995 por Héctor López Girondo, Raquel Prestigiacomo y Fabián Uccello. Ellos, con la intención de combinar investigación histórica, teatro y humor, dieron origen a un nuevo género: la comedia histórica. Este volumen recopila cuatro comedias que versan sobre temas de historia argentina presentes en la currícula de los últimos años de la educación primaria: la creación de la bandera argentina, la declaración de la independencia, los próceres libertadores de América y las tradiciones nacionales.'
        />
        <Publicacion
          tituloPublicacion='COMEDIAS DEL MUSEO VIAJERO 2'
          imagenPublicacion={libroComedias02}
          obrasIncluidas={obrasIncluidas02}
          linkPublicacion='https://www.eudeba.com.ar/Papel/9789502333434/Comedias+del+Museo+Viajero+2'
          infoPublicacion='El Museo Viajero es una compañía de teatro e investigación histórica fundada en 1995 por Héctor López Girondo, Raquel Prestigiacomo y Fabián Uccello. Ellos, con la intención de combinar investigación histórica, teatro y humor, dieron origen a un nuevo género: la comedia histórica. Este volumen recopila cuatro comedias que versan sobre temas de historia argentina presentes en la currícula de los primeros años de la educación primaria: la revolución de Mayo, las luchas por la independencia y el cruce de los Andes.'
        />
        <Publicacion
          tituloPublicacion='La Pequeña Aldea'
          imagenPublicacion={libroAldea}
          obrasIncluidas={obrasIncluidasAldea}
          linkPublicacion='https://www.eudeba.com.ar/Papel/9789502323213/La+peque%c3%b1a+aldea'
          infoPublicacion='Los años comprendidos entre 1800 y 1860 constituyen una etapa rica de nuestra historia y al mismo tiempo difícil de comprender por la intrincada red de corrientes internas que se enfrentan y entrelazan. A pesar de estas dificultades, los autores, amalgamando sus conocimientos históricos con una exposición sencilla y amena, logran que tanto los hechos mínimos como los procesos complejos se vean reflejados en lo cotidiano. Las páginas de este libro permiten que el lector conozca minuciosamente la vida de esos años, desde la cocina hasta las reuniones sociales, desde los bailes a las corridas de toros, sin dejar de lado la importancia de la comida, los recursos médicos, los juegos de mayores y los entretenimientos infantiles, la moda y el gusto femenino, el protocolo social propio de la clase alta, la sencillez de la naciente clase media y la escasez de recursos de los desposeídos.'
        />
      </Container>
      <ScrollPercentage minScroll={0} maxScroll={95} />
    </>
  );
};
