import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContex } from "../../context/DataContext";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ObraCard } from "./ObraCard";
import styles from "./Repertorio.module.scss";

const RepertorioPorTema = () => {
  const { temasObras } = useContext(DataContex);
  const { tema: temaParams } = useParams(); // Captura el parámetro 'tema' de la URL
  const [temaToShow, setTemaToShow] = useState(null);
  const [obrasEncontradas, setObrasEncontradas] = useState([]);
  const { dataSource: data, paths } = useContext(DataContex);
  const navigate = useNavigate();

  const buscarTemaPorID = (temaID) => {
    // Buscar el tema por temaID
    return temasObras.find((tema) => tema.temaID === temaID);
  };

  const filtrarPorPropiedad = ({ array, propiedad, valor }) => {
    return array.filter((item) => item[propiedad] === valor);
  };

  const handleClick = (obraElegida) => {
    navigate(`/${paths.obras}/${obraElegida}`);
  };

  const handleClickRepertorio = (obraElegida) => {
    navigate(`/${paths.obras}`);
  };

  useEffect(() => {
    // Buscar el tema y establecerlo
    const temaEncontrado = buscarTemaPorID(temaParams);
    const temaTlite = temaEncontrado?.temaTlite; // Usar ?. para manejar posibles valores nulos
    setTemaToShow(temaTlite);
    // Filtrar las obras una vez que temaToShow ha sido establecido
    if (temaTlite) {
      const obrasFiltradas = filtrarPorPropiedad({
        array: data,
        propiedad: "temaObra",
        valor: temaTlite,
      });
      setObrasEncontradas(obrasFiltradas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temaParams, temasObras]);

  return (
    <Container className={`mt-5 ${styles.obrasPara}`}>
      <>
        {temaToShow ? (
          <h3>
            {obrasEncontradas.length > 1 ? (
              <>
                <span className={`${styles.cantidadDeObras}`}>
                  {obrasEncontradas.length}
                </span>{" "}
                <span> Obras seleccionadas: </span>
              </>
            ) : (
              <>
                <span className={`${styles.cantidadDeObras}`}>
                  {obrasEncontradas.length}
                </span>{" "}
                <span> Obra seleccionada: </span>
              </>
            )}
            {temaToShow}
          </h3>
        ) : (
          <h1>Cargando...</h1>
        )}
        <Row xs={1} md={4} xxl={5} className='g-4 mb-5'>
          {obrasEncontradas.map((data, id) => (
            <Col key={id}>
              <ObraCard obra={data} handleClick={handleClick} />
            </Col>
          ))}
        </Row>
        <Row className={`justify-content-center mt-5 mb-5`}>
          <Col md={4} className='d-grid' onClick={handleClickRepertorio}>
            <Button size='lg'>Ver todas nuestras obras</Button>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default RepertorioPorTema;
