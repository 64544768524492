import { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { DataContex } from "../../context/DataContext";
import styles from "./ObrasPorMes.module.scss";
import { ObraEnCartelera } from "../ContenidoCartelera/ObraEnCartelera";
import { FuncionRow } from "./FuncionRow";

export const ObrasPorMes = ({ mesesAMostrar = 2 }) => {
  const { obrasDataMerged: data, windowSize } = useContext(DataContex);
  const currentDate = moment();
  const monthsFromNow = moment().add(mesesAMostrar, "months");
  const monthlyData = data.reduce((acc, item) => {
    const dateFuncion = moment(item.fechaFuncion, "D [de] MMMM [de] YYYY");
    if (
      dateFuncion.isValid() &&
      dateFuncion.isSameOrAfter(currentDate) &&
      dateFuncion.isSameOrBefore(monthsFromNow)
    ) {
      const monthYear = dateFuncion.format("M-YYYY");

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(item);
    }

    return acc;
  }, {});

  const sortedMonths = Object.keys(monthlyData)
    .map((key) => moment(key, "M-YYYY").toDate())
    .sort((a, b) => a - b)
    .map((date) => moment(date).format("M-YYYY"));

  function unificarObras(obraJSON) {
    const meses = Object.keys(obraJSON); // Obtener los meses dinámicamente
    const unificado = {};

    meses.forEach((mes) => {
      unificado[mes] = [];

      const idFunciones = {};

      obraJSON[mes].forEach((obra) => {
        const {
          ID,
          fechaFuncion,
          precio,
          hora,
          direccion,
          agotada,
          sala,
          promoName,
          promoDescription,
        } = obra;
        if (!idFunciones[ID]) {
          idFunciones[ID] = { ...obra, funciones: [] };
          delete idFunciones[ID].fechaFuncion; // Eliminamos la fecha de función repetida
          delete idFunciones[ID].precio; // Eliminamos el precio repetido
          delete idFunciones[ID].hora; // Eliminamos la hora repetida
          delete idFunciones[ID].direccion; // Eliminamos la hora repetida
          delete idFunciones[ID].agotada; // Eliminamos la hora repetida
        }

        idFunciones[ID].funciones.push({
          fechaFuncion,
          precio,
          hora,
          direccion,
          agotada,
          sala,
          promoName,
          promoDescription,
        });
      });

      for (const id in idFunciones) {
        unificado[mes].push(idFunciones[id]);
      }
    });

    return unificado;
  }

  // Llamamos a la función con el objeto JSON proporcionado
  const resultado = unificarObras(monthlyData);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  return (
    <Container fluid className={styles.obrasPorMes}>
      <Container>
        <Row className='mt-5 mb-5'>
          {sortedMonths.length > 0 ? (
            <h2>Próximas funciones en los teatros</h2>
          ) : (
            ""
          )}

          <Accordion flush>
            {sortedMonths.map((key, id) => (
              <Accordion.Item key={key} eventKey={`${id}`}>
                <Accordion.Header>
                  {moment(key, "M-YYYY").format("MMMM YYYY")}
                </Accordion.Header>
                <Accordion.Body>
                  {resultado[key].map((item, id) => (
                    <Col key={id}>
                      {resultado[key].length > 1 ? (
                        <Accordion flush>
                          <Accordion.Header>{item.title}</Accordion.Header>
                          <Accordion.Body>
                            <Row
                              key={id}
                              className={`mt-5 mb-2 ${styles.obrasRow}`}>
                              {isXs ? "" : <h3>{item.title}</h3>}
                              <Col className={`order-sm-1 mb-3`}>
                                <ObraEnCartelera data={item} id={id} />
                              </Col>
                              <Col md={8} className={`order-sm-0`}>
                                <ul className={``}>
                                  {item.funciones.map((data, id) => (
                                    <FuncionRow
                                      key={id}
                                      data={data}
                                      id={id}
                                      item={item}
                                    />
                                  ))}
                                </ul>
                              </Col>
                              <br />
                            </Row>
                          </Accordion.Body>
                        </Accordion>
                      ) : (
                        <Accordion.Body>
                          <Row
                            key={id}
                            className={`mt-5 mb-2 ${styles.obrasRow}`}>
                            {isXs ? "" : <h3>{item.title}</h3>}
                            <Col className={`order-sm-1 mb-3`}>
                              <ObraEnCartelera data={item} id={id} />
                            </Col>
                            <Col md={8} className={`order-sm-0`}>
                              <ul className={``}>
                                {item.funciones.map((data, id) => (
                                  <FuncionRow
                                    key={id}
                                    data={data}
                                    id={id}
                                    item={item}
                                  />
                                ))}
                              </ul>
                            </Col>
                            <br />
                          </Row>
                        </Accordion.Body>
                      )}
                    </Col>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Row>
      </Container>
    </Container>
  );
};
