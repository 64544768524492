import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";

import styles from "./ContactButton.module.scss";
import { useState } from "react";

export const ContactButton = ({
  buttonIcon,
  label,
  link,
  backColor = "white",
  backColorOver = "whitesmoke",
}) => {
  const [color, setColor] = useState(backColor);

  const handleOver = () => {
    setColor(backColorOver);
  };
  const handelOut = () => {
    setColor(backColor);
  };

  return (
    <Col xs={12} md={4} className={`${styles.contactChanel} d-flex`}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onMouseOver={handleOver}
        onMouseOut={handelOut}
      >
        <Row className={`align-items-center`}>
          <Col
            className={`${styles.iconConatiner}`}
            style={{ backgroundColor: `${color}` }}
          >
            <FontAwesomeIcon icon={buttonIcon} size="2xl" />
          </Col>
          <Col
            className={`${styles.textContainer}`}
            style={{ color: `${color}` }}
          >
            {label}
          </Col>
        </Row>
      </a>
    </Col>
  );
};
