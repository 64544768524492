import moment from "moment/moment";

// arrayUtils.js
export const sortArray = (array, criterion) => {
    const compareDates = (date1, date2) => {
        const momentDate1 = moment(date1, "DD/MM/YYYY");
        const momentDate2 = moment(date2, "DD/MM/YYYY");
        if (momentDate1.isAfter(momentDate2)) return -1;
        if (momentDate1.isBefore(momentDate2)) return 1;
        return 0;
    };

    const sortedArray = [...array];

    return sortedArray.sort((a, b) => {
        switch (criterion) {
            case "date":
                return compareDates(a.date, b.date);
            case "medio":
                if (a.medio < b.medio) return -1;
                if (a.medio > b.medio) return 1;
                return 0;
            case "title":
                if (a.title < b.title) return -1;
                if (a.title > b.title) return 1;
                return 0;
            case "autor":
                if (a.autor < b.autor) return -1;
                if (a.autor > b.autor) return 1;
                return 0;
            default:
                return 0;
        }
    });
};
