import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LottieConatiner } from "../LottieContainer/LottieConatiner";
import { scrollDown } from "../../assets";

export const ScrollPercentage = ({ minScroll = 0, maxScroll = 95 }) => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.clientHeight;
    const scrollPosition = window.scrollY;
    const percentage = (scrollPosition / (fullHeight - windowHeight)) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {scrollPercentage >= minScroll && scrollPercentage < maxScroll ? (
        <Row
          className="text-center"
          style={{
            fontSize: "48px",
            position: "fixed",
            bottom: "3%",
            zIndex: "11",
            width: "100%",
            margin: "0px",
            padding: "0px",
          }}
        >
          <Container fluid>
            <Container>
              <Row className="justify-content-center">
                <Col
                  xs={4}
                  md={2}
                  lg={1}
                  style={{
                    borderRadius: "12px",
                    padding: "0px",
                  }}
                >
                  <LottieConatiner
                    lottieJason={scrollDown}
                    viewerHeigth="2em"
                    animSpeed={50}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
