import { useContext, useState, useEffect } from "react";
import Helmet from "../Helmet";
import { sortArray } from "../../helpers/sortArrayPrensa";
import { Container, Row, ButtonGroup, Button, Col } from "react-bootstrap";
import { DataContex } from "../../context/DataContext";
import { NotaDePrensa } from "../NotaDePrensa/NotaDePrensa";
import { paths } from "../../data/dataSourese";

export const Prensa = () => {
  const { prensaData } = useContext(DataContex);

  const [sortedArray, setSortedArray] = useState(prensaData);
  const [sortCriteria, setSortCriteria] = useState("date");

  const handleSortCriteria = (criteria) => {
    setSortCriteria(criteria);
  };

  useEffect(() => {
    setSortedArray(sortArray(prensaData, sortCriteria));
  }, [prensaData, sortCriteria]);

  return (
    <>
      <Helmet
        title={`Notas de prensa`}
        link={`/${paths.prensa}`}
        description={`Explora una colección de notas de prensa publicadas sobre El Museo Viajero. Descubre la cobertura mediática de nuestras representaciones teatrales, eventos culturales y más. Mantente al tanto de las últimas noticias y reseñas sobre nuestras actividades, todo en un solo lugar.`}
        location={{}}
      />
      <Container>
        <Row>
          <Col xs={12} className='text-center mt-5'>
            <h2>Notas de prensa</h2>
          </Col>
          <Col></Col>
          <Col md={8}>
            <Row className='mt-2'>
              <ButtonGroup aria-label='Basic example'>
                <Button
                  variant='primary'
                  onClick={() => handleSortCriteria("date")}
                  active={sortCriteria === "date"}>
                  Fecha
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSortCriteria("medio")}
                  active={sortCriteria === "medio"}>
                  Medio
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSortCriteria("title")}
                  active={sortCriteria === "title"}>
                  Título
                </Button>
                <Button
                  variant='primary'
                  onClick={() => handleSortCriteria("autor")}
                  active={sortCriteria === "autor"}>
                  Autor
                </Button>
              </ButtonGroup>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Row xs={1} md={2} lg={3} xl={4} className='g-4 mt-5 mb-5'>
          {sortedArray.map((data, id) => (
            <NotaDePrensa key={id} data={data} id={id} />
          ))}
        </Row>
      </Container>
    </>
  );
};
