import { useContext, useState, useEffect } from "react";
import { DataContex } from "../../context/DataContext";
import { Col, Container, Form, Row } from "react-bootstrap";
import { BannerTopPages } from "../BannerTopPages/BannerTopPages";
import WhatsAppButtonReserva from "../WhatsAppButton/WhatsAppButtonReservar";

import styles from "./CalculadorDeFuncion.module.scss";
import { calculador } from "../../assets";
import Helmet from "../Helmet";

export const CalculadorDeFuncion = () => {
  const { calculatorData, windowSize } = useContext(DataContex);

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  const [cantidadDeAlumnos, setCantidadDeAlumnos] = useState(null);
  const [turnosDeLaEscuela, setTurnosDeLaEscuela] = useState(0);
  const [alumnosMinimos, setAlumnosMinimos] = useState(0);
  const [textoOpcionesEncontradas, setTextoOpcionesEncontradas] = useState("");
  const [cantidadOptimaDeAlumnos, setCantidadOptimaDeAlumnos] = useState(150);

  useEffect(() => {
    setAlumnosMinimos(
      calculatorData.reduce(
        (min, funcion) => Math.min(min, funcion.publicoMinPorFuncion),
        Infinity
      )
    );
    setCantidadOptimaDeAlumnos(
      calculatorData.reduce(
        (min, funcion) => Math.min(min, funcion.publicoMaxPorFuncion),
        Infinity
      )
    );
  }, [calculatorData]);

  const buscarFuncion = ({ alumnos, tipoDeJornada, funciones }) => {
    return funciones.filter((funcion) => {
      const isWithinCapacity =
        //   alumnos <= funcion.publicoMaxPorFuncion * funcion.totalDeFunciones &&
        alumnos >= funcion.publicoMinPorFuncion * funcion.totalDeFunciones;

      // if (tipoDeJornada === "simple") {
      //   return isWithinCapacity && funcion.tipoDeJornada === "simple";
      // }

      return isWithinCapacity;
    });
  };

  const resultado = buscarFuncion({
    alumnos: cantidadDeAlumnos,
    tipoDeJornada: turnosDeLaEscuela === 1 ? "simple" : "doble",
    funciones: calculatorData,
  });

  const formatCurrency = (value) => {
    return `$${Math.round(value).toLocaleString("es-AR")}`;
  };
  //   const formatNumber = (value) => {
  //     return `${Math.round(value)}`;
  //   };

  const findClosestTo = (funciones, cantidadDeAlumnos, target) => {
    let closest = null;
    let minDiff = Infinity;
    let minCost = Infinity;

    funciones.forEach((funcion) => {
      const diff = Math.abs(
        target - cantidadDeAlumnos / funcion.totalDeFunciones
      );
      const costPerStudent = funcion.valorDeLaFuncion / cantidadDeAlumnos;

      if (diff < minDiff || (diff === minDiff && costPerStudent < minCost)) {
        closest = funcion;
        minDiff = diff;
        minCost = costPerStudent;
      }
    });

    return closest;
  };

  const closestTo = findClosestTo(
    resultado,
    cantidadDeAlumnos,
    cantidadOptimaDeAlumnos
  );

  useEffect(() => {
    if (resultado.length === 1) {
      setTextoOpcionesEncontradas("Opción para visitar el colegio:");
    } else {
      setTextoOpcionesEncontradas("Opciones para visitar el colegio:");
    }
  }, [resultado]);

  return (
    <>
      <Helmet
        title='Calculador de Visitas - Museo Viajero'
        link='/calculador'
        location={{}}
        description='Calcula el costo de la visita del Museo Viajero a tu escuela'
      />
      <Container fluid>
        {isXs ? (
          <BannerTopPages
            img={calculador}
            positionY={"30%"}
            margBottom={"-30%"}
          />
        ) : (
          <BannerTopPages
            img={calculador}
            positionY={"10%"}
            margBottom={"-18%"}
          />
        )}
        <Container>
          <Row
            className={`justify-content-center ${styles.calculador} ${styles.main}`}>
            <Col xs={12} className={`text-center `}>
              <h1>Calcular costo de nuestra visita a tu escuela</h1>
            </Col>
            <Col xs={12} sm={8} md={8} lg={6} className={``}>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group className='mb-3' controlId='cantidadAlumnos'>
                  <Form.Label>
                    <strong>
                      ¿Cuántos alumnos tiene tu escuela? <br />
                    </strong>
                    Ingresa la cantidad de alumnos para calcular el costo de
                    nuestra visita.
                  </Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Cantidad de alumnos'
                    value={cantidadDeAlumnos !== null ? cantidadDeAlumnos : ""}
                    onChange={(e) => setCantidadDeAlumnos(e.target.value)}
                  />
                </Form.Group>
              </Form>
              <Row>
                <Col>
                  {cantidadDeAlumnos !== null && (
                    <>
                      {cantidadDeAlumnos < alumnosMinimos ? (
                        "La cantidad de alumnos es menor al mínimo requerido"
                      ) : (
                        <>
                          <p>
                            Cobramos un monto fijo según la cantidad de
                            funciones que hagamos. Podemos hacer 1 o 2 funciones
                            en cada turno, separando los ciclos.
                          </p>
                          <br />
                          <p>
                            <strong>
                              Encontramos: <br />
                            </strong>
                            {resultado.length + " " + textoOpcionesEncontradas}
                          </p>
                        </>
                      )}
                    </>
                  )}
                  <ul>
                    {resultado.map((funcion, id) => (
                      <li
                        key={id}
                        className={`${
                          funcion === closestTo ? styles.best : ""
                        } position-relative`}>
                        <Row className={`align-items-center `}>
                          {funcion === closestTo && (
                            <Col xs={12}>
                              <h5>Mejor opción</h5>
                            </Col>
                          )}
                          <Col md={4} className={` h-100`}>
                            <Row className={`align-items-center`}>
                              <Col
                                xs={12}
                                md={2}
                                className={`${styles.capitularConatiner}`}>
                                <p className={`${styles.capitular}`}>
                                  <strong>{funcion.turno.charAt(0)}</strong>
                                </p>
                              </Col>
                              <Col>
                                <p>{funcion.turno.substring(1)}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={4}>
                            <p>
                              Valor total de la visita: <br />
                              <strong
                                className={`${
                                  funcion.promoActiva && styles.tachado
                                }`}>
                                {formatCurrency(funcion.valorDeLaFuncion)}
                              </strong>
                              {funcion.promoActiva && (
                                <>
                                  <br />
                                  <strong>
                                    {formatCurrency(
                                      funcion.valorDeLaFuncion -
                                        funcion.valorDeLaFuncion *
                                          funcion.porcentajeValue
                                    )}
                                  </strong>
                                </>
                              )}
                            </p>
                          </Col>
                          <Col md={4} className={`text-center`}>
                            <p>
                              Precio por alumno: <br />
                              <strong
                                className={`
                              ${styles.precioAlumno} 
                              ${funcion.promoActiva && styles.tachado}
                              `}>
                                {formatCurrency(
                                  funcion.valorDeLaFuncion / cantidadDeAlumnos
                                )}
                              </strong>
                              {funcion.promoActiva && (
                                <>
                                  <br />
                                  <strong className={`${styles.precioAlumno}`}>
                                    {formatCurrency(
                                      (funcion.valorDeLaFuncion -
                                        funcion.valorDeLaFuncion *
                                          funcion.porcentajeValue) /
                                        cantidadDeAlumnos
                                    )}
                                  </strong>
                                </>
                              )}
                            </p>
                          </Col>
                          {funcion.promoActiva && (
                            <Col xs={12} className={`${styles.promoFlag}`}>
                              <Col>
                                <p>Promoción:</p>
                              </Col>
                              <Col>
                                <p>
                                  <span>{funcion.promoNombre}</span>
                                </p>
                              </Col>
                            </Col>
                          )}
                        </Row>
                        <Row className={`justify-content-center`}>
                          <Col xs={10} md={8} className={`text-center mt-3`}>
                            <WhatsAppButtonReserva
                              text='Consultar por esta visita al colegio'
                              titleFuncion={`${funcion.turno} - Escuela con ${cantidadDeAlumnos} alumnos`}
                              className={
                                "ReservarFuncionViajera ReservarCalculador"
                              }
                              id={funcion.turno}
                            />
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>

              {cantidadDeAlumnos !== null && (
                <>
                  {cantidadDeAlumnos < alumnosMinimos ? (
                    ""
                  ) : (
                    <p className={`${styles.nota}`}>
                      <strong>
                        Descargo de Responsabilidad
                        <br />
                      </strong>
                      La información proporcionada por este calculador es solo
                      una sugerencia basada en la cantidad de alumnos y no
                      constituye un precio definitivo por alumno. El precio
                      total es fijo, determinado por la cantidad de funciones
                      contratadas. Por lo tanto, el monto a cobrar no depende de
                      la cantidad de alumnos presentes. Recomendamos revisar
                      esta información con nuestro equipo para obtener más
                      detalles y asegurar una planificación adecuada.
                    </p>
                  )}
                </>
              )}
            </Col>
            {/* <Col md={6}>
              <p>Cantidad de alumnos de la escuela: {cantidadDeAlumnos}</p>
              <p>Turnos de la escuela: {turnosDeLaEscuela}</p>
              <pre>{JSON.stringify(resultado, null, 2)}</pre>
            </Col> */}
          </Row>
        </Container>
      </Container>
    </>
  );
};
