// linkPreviewUtils.js
export const findImage = (metaTags, fallbackImage, setAltText) => {
    // Función para validar una URL
    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
        } catch (error) {
            return false;
        }
    };

    // Verificar si fallbackImage es una URL válida
    if (isValidUrl(fallbackImage)) {
        setAltText("Fallback image");
        return fallbackImage;
    }

    // Buscar en metaTags la URL de la imagen preferida en el orden de prioridad deseado.
    if (
        metaTags["og:image"] &&
        metaTags["og:image"] !== "#" &&
        isValidUrl(metaTags["og:image"])
    ) {
        setAltText("OG image");
        return metaTags["og:image"];
    }
    if (
        metaTags["twitter:image"] &&
        metaTags["twitter:image"] !== "#" &&
        isValidUrl(metaTags["twitter:image"])
    ) {
        setAltText("Twitter image");
        return metaTags["twitter:image"];
    }
    if (
        metaTags["msapplication-TileImage"] &&
        metaTags["msapplication-TileImage"] !== "#" &&
        isValidUrl(metaTags["msapplication-TileImage"])
    ) {
        setAltText("MS Tile image");
        return metaTags["msapplication-TileImage"];
    }
    if (
        metaTags["favicon"] &&
        metaTags["favicon"] !== "#" &&
        isValidUrl(metaTags["favicon"])
    ) {
        setAltText("FavIcon image");
        return metaTags["favicon"];
    }

    // Si fallbackImage no es una URL válida, devolver null
    return null;
};
