import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Image } from "react-bootstrap";

import styles from "./PhotoGalery.module.scss";

import { useEffect, useState } from "react";
import { MyModal } from "../MyModal/MyModal";

export const PhotoGalery = ({ obras }) => {
  const [toShow, setToShow] = useState(3);
  const [imgHeight, setImgHeight] = useState("200px");

  useEffect(() => {
    if (obras.length <= 2) {
      setToShow(1);
      setImgHeight("450px");
    } else {
      setToShow(3);
      setImgHeight("200px");
    }
  }, [obras]);

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: toShow, // Muestra 4 imágenes a la vez
    slidesToScroll: 1, // Avanza de 4 en 4
    autoplay: true,
    autoplaySpeed: 8000,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  // MyModal settings
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (data) => {
    setUrl(data);
    setShow(true);
  };

  return (
    <Container>
      <Col className={`${styles.carouselContainer}`} id="photoGalery">
        <Slider autoplay {...settings}>
          {Array.from(obras).map((data, id) => (
            <Col
              key={id}
              className={`${styles.cardContainer} ${styles.rotate3d}`}
            >
              <Col
                className={` ${styles.imgContainer} `}
                onClick={() => handleShow(data.url)}
                style={{ height: `${imgHeight}` }}
              >
                <Image
                  src={data.url}
                  alt={data.imgName}
                  className={`${styles.imgCover}`}
                  style={{ height: `${imgHeight}` }}
                />
              </Col>
            </Col>
          ))}
        </Slider>
      </Col>
      <MyModal show={show} handleClose={handleClose} url={url} />
    </Container>
  );
};
