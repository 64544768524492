import { Col, Container, Image } from "react-bootstrap";
import Slider from "react-slick";
import styles from "./SponsorSlider.module.scss";

export const SponsorSlider = ({ slides }) => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1,
    centerMode: true,
    cssEase: "linear",
    draggable: false,
    initialSlide: 4,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 5,
    speed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container fluid className={`${styles.sliderRow}`}>
      <Container className={`${styles.title}`}>
        <h2>El Museo Viajero ha recibido importantes reconocimientos</h2>
      </Container>
      <Col>
        <Slider {...settings}>
          {Array.from(slides).map((data, id) => (
            <Image key={id} src={data.svgFile} fluid />
          ))}
        </Slider>
      </Col>
    </Container>
  );
};
