import { Col, Card, Button } from "react-bootstrap";
import LinkPreview from "../LinkPreview/LinkPreview";

import styles from "./NotaDePrensa.module.scss";
import shareStyles from "../../scss/sharedStyles.module.scss";
import { isoPrimary } from "../../assets";

export const NotaDePrensa = ({ data, id }) => {
  const handleClick = (url) => {
    window.open(`${url}`, "_blnak");
  };

  return (
    <Col key={id} className={`${styles.notaDePrensa} ${shareStyles.float}`}>
      <Card className={`${styles.notaCard} h-100`}>
        <Col className={`${styles.imgContainer}`}>
          <Col className={`${styles.imgBox}`}>
            <LinkPreview url={`${data.url}`} fallbackImage={isoPrimary} />
            {/* esto se renderiza como una etiqueta <img></img> */}
          </Col>
        </Col>
        <Card.Body>
          <Card.Title className={`${styles.titleNota}`}>
            {data.title}
          </Card.Title>
          <ul>
            <li>
              Medio: <br />
              <b>{data.medio}</b>
            </li>
            <li>
              Por: <br />
              <b>{data.autor}</b>
            </li>
            <li>
              Publicado: <br />
              <b>{data.date}</b>
            </li>
          </ul>
        </Card.Body>
        <Card.Footer className='text-center'>
          <Button onClick={() => handleClick(data.url)}>Ver Nota</Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};
