import React from "react";
import { VideoLoader } from "../VideoLoader/VideoLoader";
import { Col, Container, Row } from "react-bootstrap";
import { MaterialDownloader } from "../MaterialDownloader/MaterialDownloader";
import {
  pdfDiarioDeMarcha01,
  pdfDiarioDeMarcha02,
  pdfDiarioDeMarcha03,
  pdfDiarioDeMarcha04,
  pdfDiarioDeMarcha05,
  pdfDiarioDeMarcha06,
  pdfDiarioDeMarcha07,
} from "../../assets";
import Helmet from "../Helmet";
import { paths } from "../../data/dataSourese";

export const DiarioDeMarcha = () => {
  const tiposDisponibles = {
    Actividad: "Actividad",
    Investigacion: "Investigación",
    Crucigrama: "Crucigrama",
  };

  const data = [
    {
      link: pdfDiarioDeMarcha01,
      tipo: tiposDisponibles.Investigacion,
      description: "Investigación sobre el Diario de marcha de Manuel Belgrano",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha02,
      tipo: tiposDisponibles.Actividad,
      description:
        "Responder las siguientes preguntas sobre el Diario de Marcha a Rosario",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha03,
      tipo: tiposDisponibles.Crucigrama,
      description: "Crucigrama sobre el Diario de Marcha a Rosario",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha04,
      tipo: tiposDisponibles.Actividad,
      description:
        "Poner en la carreta los elementos que llevaron en el viaje a Rosario",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha05,
      tipo: tiposDisponibles.Actividad,
      description:
        "Completar los blancos con la palabra que corresponde al dibujo",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha06,
      tipo: tiposDisponibles.Investigacion,
      description: "El Diario de Marcha",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
    {
      link: pdfDiarioDeMarcha07,
      tipo: tiposDisponibles.Actividad,
      description: "Pone el nombre de los pueblos por los que pasó Belgrano",
      inicial: false,
      primerCiclo: false,
      segundoCiclo: true,
      tercerCiclo: true,
      secundario: true,
    },
  ];

  return (
    <>
      <Helmet
        title='Diario de Marcha de Manuel Belgrano'
        description='Diario de Marcha a Rosario escrito por Manuel Belgrano y adaptado a video documental por El Museo Viajero, especialmente para niños y jóvenes. Un viaje de quince días a pie que terminó en la creación de la bandera.'
        location={{}} // Asegúrate de que `location` sea un objeto vacío
        link={`/${paths.diarioDeMarcha}`}
      />
      <Container fluid>
        <Row className='justify-content-md-center mt-5 mb-5'>
          <Col md={9}>
            <Row>
              <Col md={8}>
                <VideoLoader videoId='085cWKKMx6o' />
              </Col>
              <Col>
                {data.map((item, id) => (
                  <MaterialDownloader
                    key={id}
                    data={item}
                    numeroDeDescargas={false}
                  />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
