// generateStructuredData.js
const DOMAIN = "https://elmuseoviajero.com.ar";

const stripHtml = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let textContent = tempElement.textContent || tempElement.innerText || "";
    textContent = textContent.replace(/\s+/g, " ").trim();
    return textContent;
};

const generateStructuredData = (props) => {
    const {
        title,
        description,
        type,
        startDate,
        location,
        imageCard,
        awards,
        temas,
        schedule,
        sendStructuredData,
        // salas = [],
        keywords,
    } = props;

    let newDescription = ''


    if (stripHtml(description) === '' && temas.length > 0) {
        newDescription = temas.map(item => item.tema).join(" ")
    } else { newDescription = stripHtml(description) }

    if (!sendStructuredData) {
        return null;
    } else {
        const structuredData = {
            "@context": "http://schema.org",
            "@type": type,
            name: title,
            description: `${newDescription}`,
            startDate: `${startDate}T00:00:00-03:00`, // Adding time zone to startDate of main event
            organizer: {
                "@type": "Organization",
                name: "El Museo Viajero",
                url: DOMAIN,
            },
            keywords: `${keywords && keywords.map((item) => item).join(", ")}`,
            location: {
                "@type": "Place",
                name: "Salas del Museo Viajero o en las escuelas",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: location.addressLocality || "CABA",
                    addressRegion: location.addressRegion || "Buenos Aires",
                },
                url: location.url || "Unknown URL",
            },
            image: [DOMAIN + imageCard],
            performer: {
                "@type": "PerformingGroup",
                name: "El Museo Viajero",
            },
            workPerformed: {
                "@type": "CreativeWork",
                name: title,
                description: newDescription,
                award: awards,
            },
            about: {
                "@type": "ItemList",
                itemListElement: temas.map((tema, index) => ({
                    "@type": "ListItem",
                    position: index + 1,
                    item: tema.tema,
                })),
            },
            eventSchedule: schedule && schedule.length > 0
                ? schedule.map((funcion) => {
                    // Encuentra la sala correspondiente por nombre
                    // const sala = salas.find(s => s.nombreSala === funcion.location.name);
                    return {
                        "@type": "Schedule",
                        startDate: `${funcion.startDate}T${funcion.startTime}:00-03:00`, // Adding time zone to each event
                        startTime: funcion.startTime
                    };
                })
                : undefined,
        };

        console.log('Schema created');
        return structuredData;
    };
};

export default generateStructuredData;
