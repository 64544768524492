import { gsap } from "gsap";
import { Col } from "react-bootstrap";
import { useRef, useEffect, useState } from "react";

export const ImageAnimated = ({ images, timeOpu = 0 }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const imageRef = useRef(null);
  const timeoutRef = useRef(null);

  const cambiarDeImagen = () => {
    if (imgIndex <= images.length - 2) {
      setImgIndex(imgIndex + 1);
    } else {
      setImgIndex(0);
    }
  };

  useEffect(() => {
    const tl = gsap.timeline();

    timeoutRef.current = setTimeout(() => {
      tl.to(imageRef.current, { duration: 1, delay: 2 }) // Espera de 1 segundo en la imagen inicial
        .to(imageRef.current, {
          y: 340,
          duration: 1,
          delay: 0.5,
          onComplete: () => {
            cambiarDeImagen();
          },
        }) // Descender la imagen durante 1 segundo
        .to(imageRef.current, {
          y: 0,
          duration: 1,
        }); // Subir la imagen de nuevo a la posición original
    }, timeOpu);

    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIndex, timeOpu]);

  return (
    <Col style={{ overflow: "hidden" }}>
      <img
        src={images[imgIndex]} // Comienza con la primera imagen en la lista
        alt='Imagen'
        ref={imageRef}
      />
    </Col>
  );
};
