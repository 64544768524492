import React, { useRef } from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import { cronologia } from "../../data/dataSourese";
import styles from "./TmeLine.module.scss";

export const TimeLine = () => {
  const sliderRef = useRef(null);
  const sliderCont = useRef(null);
  const settings = {
    centerMode: false,
    slidesToShow: 8,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 3000,
    mobileFirst: true,
    dots: false,
    infinite: false,
    focusOnSelect: true,
    useCSS: true,
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          draggable: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const settings2 = {
    centerMode: false,
    centerPadding: "20px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    mobileFirst: true,
    dots: false,
    infinite: false,
    focusOnSelect: true,
    vertical: true,
    draggable: false,
    useCSS: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {},
      },
    ],
  };

  const goToSlide = (slideIndex) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  };

  const goToSlideCon = (slideIndex) => {
    if (sliderCont.current) {
      sliderCont.current.slickGoTo(slideIndex);
    }
  };

  return (
    <>
      <Row className={`${styles.yearContainer}`}>
        <Container id="yearContainerSlider">
          <Slider ref={sliderRef} {...settings}>
            {Array.from(cronologia).map((data, id) => (
              <Col
                key={id}
                onClick={() => goToSlideCon(id)}
                className={`${styles.yearButton}`}
              >
                <Row className="h-100 lign-items-center text-center">
                  <Col>{data.year}</Col>
                </Row>
              </Col>
            ))}
          </Slider>
        </Container>
      </Row>

      <Row className="justify-content-md-center">
        <Col lg={8} className={`${styles.yearEvents}`}>
          <Slider ref={sliderCont} {...settings2}>
            {Array.from(cronologia).map((data, id) => (
              <Col
                key={id}
                id={`yearEvent${data.year}`}
                onClick={() => goToSlide(id)}
                className={`${styles.year}`}
              >
                <h4>{data.year}</h4>
                <ul>
                  {Array.from(data.yearEvent).map((data, id) => (
                    <li
                      key={id}
                      className={`${styles.yearItem}`}
                      dangerouslySetInnerHTML={{ __html: data }}
                    />
                  ))}
                </ul>
              </Col>
            ))}
          </Slider>
        </Col>
      </Row>
    </>
  );
};
