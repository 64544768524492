import { useContext, useState, useEffect } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { DataContex } from "../../context/DataContext";

export const MapButton = ({ data, text = "Ubicación" }) => {
  const { salas } = useContext(DataContex);
  const [mapLocation, setMapLocation] = useState("");

  useEffect(() => {
    // Busca la sala cada vez que 'salas' o 'data.sala' cambie.
    const salaEncontrada = salas.find((sala) => sala.nombreSala === data.sala);

    // Utiliza la evaluación opcional para evitar errores si 'salaEncontrada' es undefined.
    setMapLocation(salaEncontrada?.link || "");
  }, [salas, data.sala]); // Agrega 'data.sala' como dependencia para reaccionar a sus cambios.

  const handleClick = () => {
    // Solo intenta abrir la ventana si 'mapLocation' tiene un valor.
    if (mapLocation) {
      window.open(mapLocation, "_blank");
    }
  };

  // Opcionalmente, podrías deshabilitar el botón si no hay una ubicación disponible.
  return (
    <Button
      style={{ width: "100%" }}
      variant='primary'
      onClick={handleClick}
      disabled={!mapLocation}>
      <FontAwesomeIcon icon={faLocationDot} size='2xl' />
      <br />
      {text}
    </Button>
  );
};
